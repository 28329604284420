import { API_URL, NEW_API_URL } from "./url";

// LOGIN URL
export const LOGIN_USER = `${NEW_API_URL}/user/login`;
export const FORGOT_PASSWORD = `${NEW_API_URL}/user/forgot/password`;
export const RESET_PASSWORD = `${NEW_API_URL}/user/reset/password`;
export const LOGOUT_USER = `${NEW_API_URL}/user/logout`;

// WIN-LOSS URL
export const GET_ALL_WIN_LOSS = `${NEW_API_URL}/cater/company/winloss`;
export const GET_WIN_LOSS_BY = `${API_URL}/winloss/info`;
export const UPDATE_WIN_LOSS = `${API_URL}/winloss/update`;

export const GET_ALL_EVA_MEMBER = `${NEW_API_URL}/cater/company/eva`;

export const GET_ALL_BILLING_COMPANY = `${NEW_API_URL}/cater/company/billing`;
export const ADD_BILLING_COMPANY = `${NEW_API_URL}/cater/company/add/billing`;

// COMPANY URL
export const GET_ALL_COMPANY = `${NEW_API_URL}/cater/company/all`; // all venues->hall->venue employee->menu->customers->event->bookings
export const GET_ALL_USER = `${NEW_API_URL}/cater/company/user`;
export const GET_ALL_CUSTOMER = `${NEW_API_URL}/cater/company/customer`;
export const GET_COMPANY_COUNT = `${NEW_API_URL}/cater/company/analytics`;
export const SINGUP_COMPANY = `${NEW_API_URL}/cater/company/add`;

export const GET_COMPANY_PAYMENT = `${API_URL}/payment/amount`; // payment, refund, discount
export const GET_COMPANY_BY_ID = `${API_URL}/companyInfo/id`;
export const UPDATE_COMPANY = `${API_URL}/companyInfo/update`;

// SUPPORT URL
export const GET_ISSUES_LIST = `${NEW_API_URL}/support/all`;
export const SEARCH_ISSUES_LIST = `${NEW_API_URL}/support/search`;
export const UPDATE_SUPPORT_ISSUE = `${NEW_API_URL}/support/update`;

// PACKAGE URL
export const GET_PACKAGE_LIST = `${NEW_API_URL}/package/all`;
export const ADD_NEW_PACKAGE = `${NEW_API_URL}/package/add`;
export const UPDATE_PACKAGE = `${NEW_API_URL}/package/update`;

// SUBSCRIPTION URL
export const GET_SUBCRIPTION_LIST = `${NEW_API_URL}/subscription/all`;
export const GET_SUBCRIPTION_BY_COMPANY = `${NEW_API_URL}/subscription/company`;
export const ADD_NEW_SUBSCRIPTION = `${NEW_API_URL}/subscription`;
export const UPDATE_SUBSCRIPTION = `${NEW_API_URL}/subscription`;
export const DELETE_SUBSCRIPTION = `${NEW_API_URL}/subscription`;
