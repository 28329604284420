import {
	// FETCH
	FETCH_CATER_PACKAGE_REQUEST,
	FETCH_CATER_PACKAGE_SUCCESS,
	FETCH_CATER_PACKAGE_FAILURE,
	// ADD
	ADD_CATER_PACKAGE_REQUEST,
	ADD_CATER_PACKAGE_SUCCESS,
	ADD_CATER_PACKAGE_FAILURE,
	// UPDATE
	UPDATE_CATER_PACKAGE_REQUEST,
	UPDATE_CATER_PACKAGE_SUCCESS,
	UPDATE_CATER_PACKAGE_FAILURE,
	FETCH_USER_LOGOUT,
} from "../action-types";

import axios from "axios";
import {
	GET_PACKAGE_LIST,
	ADD_NEW_PACKAGE,
	UPDATE_PACKAGE,
} from "../../constants/endpoints";

import setAuthToken from "../../helpers/setAuthToken";

import {
	isEmptyObject,
	sessionInfo,
	showToastMessage,
} from "../../helpers/functions";

async function _handleError(error, dispatch) {
	var status = 504;

	if (!isEmptyObject(error.response)) {
		status = error.response.status;
	}

	if (status === 403) {
		// history.push({ pathname: "/" });dispatch();
		await dispatch({
			type: FETCH_USER_LOGOUT,
			error: error.response.data.message,
		});
		showToastMessage(error.response.data.message, "E");
	} else if (status === 400) {
		showToastMessage(error.response.data.message, "E");
		await dispatch({
			type: FETCH_CATER_PACKAGE_FAILURE,
			error: error.response.data.message,
		});
	} else {
		showToastMessage(error.message, "E");
		await dispatch({
			type: FETCH_CATER_PACKAGE_FAILURE,
			error: error.message,
		});
	}
}

export const getCaterPackageList =
	(package_status = "", package_duration = "") =>
	async (dispatch) => {
		const sessionData = await sessionInfo();

		if (localStorage.fooventLoggedIn) {
			setAuthToken(sessionData.token);
		}

		dispatch({
			type: FETCH_CATER_PACKAGE_REQUEST,
		});

		await axios
			.get(
				`${GET_PACKAGE_LIST}?package_status=${package_status}&package_duration=${package_duration}`
			)
			.then((response) => {
				if (response.data.success) {
					dispatch({
						type: FETCH_CATER_PACKAGE_SUCCESS,
						payload: response.data.result,
					});
				} else {
					showToastMessage(response.data.message, "E");
					dispatch({
						type: FETCH_CATER_PACKAGE_FAILURE,
						error: response.data.message,
					});
				}
			})
			.catch((err) => {
				_handleError(err, dispatch);
			});
	};

export const addCaterPackage = (newCaterPackage) => async (dispatch) => {
	const sessionData = await sessionInfo();

	if (localStorage.fooventLoggedIn) {
		setAuthToken(sessionData.token);
	}

	dispatch({
		type: ADD_CATER_PACKAGE_REQUEST,
	});

	await axios
		.post(ADD_NEW_PACKAGE, newCaterPackage)
		.then((response) => {
			if (response.data.success) {
				dispatch({
					type: ADD_CATER_PACKAGE_SUCCESS,
					payload: response.data.result,
				});

				showToastMessage(response.data.message, "S");
			} else {
				showToastMessage(response.data.message, "E");
				dispatch({
					type: ADD_CATER_PACKAGE_FAILURE,
					error: response.data.message,
				});
			}
		})
		.catch((err) => {
			_handleError(err, dispatch);
		});
};

export const updateCaterPackage = (caterPackage) => async (dispatch) => {
	const sessionData = await sessionInfo();

	if (localStorage.fooventLoggedIn) {
		setAuthToken(sessionData.token);
	}

	dispatch({
		type: UPDATE_CATER_PACKAGE_REQUEST,
	});

	await axios
		.put(UPDATE_PACKAGE, caterPackage)
		.then((response) => {
			if (response.data.success) {
				dispatch({
					type: UPDATE_CATER_PACKAGE_SUCCESS,
					payload: response.data.result,
				});

				showToastMessage(response.data.message, "S");
			} else {
				showToastMessage(response.data.message, "E");
				dispatch({
					type: UPDATE_CATER_PACKAGE_FAILURE,
					error: response.data.message,
				});
			}
		})
		.catch((err) => {
			_handleError(err, dispatch);
		});
};
