import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";

import "./caterSubscription.style.scss";
import WatchIcon from "@mui/icons-material/Watch";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";

import moment from "moment";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

// components imported
import Table from "../../components/table/Table";
import SelectBox from "../../components/select-box/select-box.input";
import TextInput from "../../components/text-input/text-input-error.component";

import { getAllCompanies } from "../../redux/actions/company-action";
import { getCaterPackageList } from "../../redux/actions/caterPackage-action";
import {
	addCaterSubscription,
	getCaterSubscriptionList,
	updateCaterSubscription,
} from "../../redux/actions/caterSubscription-action";

const caterSubscriptionSchema = yup.object().shape({
	company_info_id: yup.string().required("company is required"),
	subscription_type: yup.string().required("subscription type is required"),
	subscription_duration: yup
		.number()
		.typeError("subscription duration number is required")
		.required("subscription duration number is required"),
	subscription_amount: yup
		.number()
		.typeError("subscription amount number is required")
		.required("subscription amount number is required"),
	renewed_date: yup.string().required("required"),
	expiration_date: yup.string().required("required"),
});

export default function CaterSubscription() {
	const dispatch = useDispatch();
	const currentDate = moment().format("YYYY-MM-DD");
	const [actionButton, setActionButtonState] = useState("add");
	const [oldSubscription, setOldSubscriptionState] = useState({});
	const companies = useSelector((state) => state.company.companies);
	const caterSubscriptions = useSelector(
		(state) => state.caterSubscription.caterSubscriptionList
	);

	const caterPackages = useSelector(
		(state) => state.caterPackage.caterPackageList
	);

	// for select box
	const companyInfos = companies.map((company) => {
		return {
			id: company.company_info_id,
			value: company.company_name,
		};
	});
	const caterPackageDropdown = caterPackages.map((caterPackage) => {
		return {
			id: caterPackage.cater_package_id,
			value: caterPackage.package_type,
			package_duration: caterPackage.package_duration,
			package_amount: caterPackage.package_amount,
		};
	});
	// subscription form control
	const {
		control: caterSubscriptionControl,
		handleSubmit: handleCaterSubscriptionSubmit,
		formState: { errors: caterSubscriptionErrors },
		reset: catersubscriptionReset,
		watch: subscriptionTypeWatch,
		setValue: setSubscriptionValue,
	} = useForm({
		defaultValues: {},
		mode: "onBlur",
		resolver: yupResolver(caterSubscriptionSchema),
	});

	useEffect(() => {
		dispatch(getCaterSubscriptionList());
		dispatch(getAllCompanies());
		dispatch(getCaterPackageList("active"));
	}, [dispatch]);

	// handling form
	const _caterSubscriptionFormSubmit = (data) => {
		const addData = {
			company_info_id: data.company_info_id,
			subscription_type: data.subscription_type.toLowerCase(),
			subscription_duration: data.subscription_duration,
			renewed_date: data.renewed_date,
			expiration_date: data.expiration_date,
			subscription_amount: data.subscription_amount,
		};

		switch (actionButton) {
			case "add":
				dispatch(
					addCaterSubscription({
						...addData,
						subscription_status: "active",
					})
				);
				break;
			case "edit":
				dispatch(
					updateCaterSubscription({
						...data,
						subscription_status: data.subscription_status,
					})
				);
				break;
			case "renew":
				dispatch(
					addCaterSubscription({
						...addData,
						subscription_status: data.subscription_status,
					})
				);
				dispatch(
					updateCaterSubscription({
						...oldSubscription,
						cater_subscription_id: data.cater_subscription_id,
						company_info_id: data.company_info_id,
						subscription_status: "expired",
					})
				);
				break;
			default:
				break;
		}

		catersubscriptionReset({
			company_info_id: "",
			subscription_type: "",
			subscription_duration: "",
			subscription_amount: "",
			renewed_date: "",
			expiration_date: "",
		});
		setSubscriptionValue("subscription_amount", "");
		setSubscriptionValue("subscription_duration", "");
		setActionButtonState("add");
	};

	const _editCaterSubscription = (rowData, action) => {
		catersubscriptionReset(rowData);
		setSubscriptionValue(
			"subscription_amount",
			rowData.subscription_amount
		);
		setSubscriptionValue(
			"subscription_duration",
			rowData.subscription_duration
		);

		if (action === "renew") {
			setOldSubscriptionState(rowData);
			setSubscriptionValue("renewed_date", rowData.expiration_date);
			setSubscriptionValue(
				"expiration_date",
				moment(rowData.expiration_date)
					.add(rowData.subscription_duration, "M")
					.format("YYYY-MM-DD")
			);
		}

		setActionButtonState(action);
	};

	const subscriptionWatchFields = subscriptionTypeWatch("subscription_type");
	const _packageChoose = () => {
		if (subscriptionWatchFields) {
			const packageValue = caterPackageDropdown.find(
				(caterPackage) => caterPackage.id === subscriptionWatchFields
			);
			setSubscriptionValue(
				"subscription_amount",
				packageValue.package_amount
			);
			setSubscriptionValue(
				"subscription_duration",
				packageValue.package_duration
			);
		}
	};

	// displaying package listed
	const subscriptionListTable = [
		{
			title: (
				<>
					<CardMembershipIcon className="menu-icon" /> Company
				</>
			),
			render: (rowData) => {
				return <span>{rowData.company_info.company_name}</span>;
			},
		},
		{
			title: (
				<>
					<WatchIcon className="menu-icon" /> Subscription Type
				</>
			),
			render: (rowData) => {
				return <span>{rowData.cater_package.package_type}</span>;
			},
		},
		{
			title: (
				<>
					<PriceCheckIcon className="menu-icon" /> Subscription
					Duration
				</>
			),
			render: (rowData) => {
				return <span>{rowData.subscription_duration} Month/s</span>;
			},
		},
		{
			title: (
				<>
					<MilitaryTechIcon className="menu-icon" /> Renewed Date
				</>
			),
			render: (rowData) => {
				return <span>{rowData.renewed_date}</span>;
			},
		},
		{
			title: (
				<>
					<MilitaryTechIcon className="menu-icon" /> Expiration Date
				</>
			),
			render: (rowData) => {
				return <span>{rowData.expiration_date}</span>;
			},
		},
		{
			title: (
				<>
					<MilitaryTechIcon className="menu-icon" /> Subscription
					Amount
				</>
			),
			render: (rowData) => {
				return <span>Rs.{rowData.subscription_amount}</span>;
			},
		},
		{
			title: (
				<>
					<MilitaryTechIcon className="menu-icon" /> Subscription
					Status
				</>
			),
			render: (rowData) => {
				return (
					<span>
						{rowData.subscription_status === "active_due"
							? "payment pending"
							: rowData.subscription_status}
					</span>
				);
			},
		},
		{
			title: (
				<>
					<SettingsSuggestIcon className="menu-icon" /> Action
				</>
			),
			render: (rowData) => {
				return (
					<div className="action-buttons-container">
						{rowData.expiration_date > currentDate ? (
							<>
								<button
									className="btn btn-outline-warning"
									onClick={() => {
										_editCaterSubscription(rowData, "edit");
									}}
								>
									Edit
								</button>

								{rowData.subscription_status ===
									"active_due" && (
									<button
										className={`btn btn-outline-danger`}
										onClick={() => {
											_editCaterSubscription(
												{
													...rowData,
													subscription_status:
														"active",
												},
												"edit"
											);
										}}
									>
										Paid
									</button>
								)}
							</>
						) : rowData.subscription_status === "expired" ? (
							<></>
						) : (
							<>
								<button
									className={`btn btn-outline-danger`}
									onClick={() => {
										_editCaterSubscription(
											{
												...rowData,
												subscription_status:
													"active_due",
											},
											"renew"
										);
									}}
								>
									Pending Renew
								</button>

								<button
									className={`btn btn-outline-success`}
									onClick={() =>
										_editCaterSubscription(
											{
												...rowData,
												subscription_status: "active",
											},
											"renew"
										)
									}
								>
									Renew
								</button>
							</>
						)}
					</div>
				);
			},
		},
	];

	// displaying form
	const caterSubscriptionForm = () => {
		return (
			<form
				key={2}
				style={{ flex: 1 }}
				onSubmit={handleCaterSubscriptionSubmit(
					_caterSubscriptionFormSubmit
				)}
			>
				<div className="subscription-form-input-container">
					<Controller
						name="company_info_id"
						control={caterSubscriptionControl}
						defaultValue=""
						render={({ field }) => (
							<SelectBox
								id="outlined-size-small company_info_id"
								className="search"
								label="Company Name"
								field={field}
								size="small"
								options={companyInfos}
								required
								errormessage={
									caterSubscriptionErrors?.company_info_id
										?.message
								}
							/>
						)}
					/>
					<Controller
						name="subscription_type"
						control={caterSubscriptionControl}
						defaultValue=""
						render={({ field }) => (
							<SelectBox
								id="outlined-size-small subscription_type"
								className="search"
								label="Subscription Type"
								field={field}
								size="small"
								options={caterPackageDropdown}
								required
								errormessage={
									caterSubscriptionErrors?.subscription_type
										?.message
								}
								onChange={_packageChoose()}
							/>
						)}
					/>
					<Controller
						name="subscription_duration"
						control={caterSubscriptionControl}
						defaultValue=""
						render={({ field }) => (
							<TextInput
								required
								id="outlined-size-small subscriptionDuration"
								label="Subscription Duration"
								type="number"
								field={field}
								errormessage={
									caterSubscriptionErrors
										?.subscription_duration?.message
								}
							/>
						)}
					/>
					<Controller
						name="subscription_amount"
						control={caterSubscriptionControl}
						defaultValue=""
						render={({ field }) => (
							<TextInput
								required
								id="outlined-size-small subscriptionAmount"
								label="Subscription Amount"
								type="number"
								field={field}
								errormessage={
									caterSubscriptionErrors?.subscription_amount
										?.message
								}
							/>
						)}
					/>
					<Controller
						name="renewed_date"
						control={caterSubscriptionControl}
						defaultValue=""
						render={({ field }) => (
							<TextInput
								required
								id="outlined-size-small renewedDate"
								label="Renewed Date"
								type="date"
								field={field}
								errormessage={
									caterSubscriptionErrors?.renewed_date
										?.message
								}
							/>
						)}
					/>
					<Controller
						name="expiration_date"
						control={caterSubscriptionControl}
						defaultValue=""
						render={({ field }) => (
							<TextInput
								required
								id="outlined-size-small expirationDate"
								label="Expiration Date"
								type="date"
								field={field}
								errormessage={
									caterSubscriptionErrors?.expiration_date
										?.message
								}
							/>
						)}
					/>
					<div className="package-action-btn">
						<button
							className="btn btn-outline-secondary text-uppercase"
							type="submit"
						>
							{actionButton === "add"
								? "Add New Subscription"
								: actionButton === "edit"
								? "Update Subscription"
								: "Renew Subscription"}
						</button>
					</div>
				</div>
			</form>
		);
	};

	return (
		<div className="subscription-main-container">
			<div className="cater-subscription-form">
				{caterSubscriptionForm()}
			</div>
			<div className="cater-subscription-list">
				<Table
					cols={subscriptionListTable}
					data={caterSubscriptions}
					isDark
					bordered
					striped
					hoverable
				/>
			</div>
		</div>
	);
}
