import axios from "axios";
import {
	GET_ALL_COMPANY,
	GET_COMPANY_COUNT,
	SINGUP_COMPANY,
	GET_ALL_USER,
	GET_ALL_CUSTOMER,
	GET_ALL_EVA_MEMBER,
	GET_ALL_BILLING_COMPANY,
	ADD_BILLING_COMPANY,
} from "../../constants/endpoints";

import {
	// add company
	ADD_COMPANY_REQUEST,
	ADD_COMPANY_SUCCESS,
	ADD_COMPANY_FAILURE,
	// company reducers
	FETCH_COMPANY_REQUEST,
	FETCH_COMPANY_SUCCESS,
	FETCH_COMPANY_FAILURE,
	FETCH_COMPANY_COUNT_SUCCESS,
	// user reducer
	FETCH_CATER_USER_REQUEST,
	FETCH_CATER_USER_SUCCESS,
	FETCH_CATER_USER_FAILURE,
	// customer reducer
	FETCH_CUSTOMER_REQUEST,
	FETCH_CUSTOMER_SUCCESS,
	FETCH_CUSTOMER_FAILURE,
	// user reducers
	FETCH_USER_FAILURE,
	FETCH_USER_LOGOUT,
	// eva nepal reducer
	FETCH_EVA_MEMBER_REQUEST,
	FETCH_EVA_MEMBER_SUCCESS,
	FETCH_EVA_MEMBER_FAILURE,
	// billing company
	FETCH_BILLING_COMPANY_REQUEST,
	FETCH_BILLING_COMPANY_SUCCESS,
	FETCH_BILLING_COMPANY_FAILURE,
	ADD_BILLING_COMPANY_REQUEST,
	ADD_BILLING_COMPANY_SUCCESS,
	ADD_BILLING_COMPANY_FAILURE,
} from "../action-types.js";

import setAuthToken from "../../helpers/setAuthToken";
import {
	sessionInfo,
	isEmptyObject,
	showToastMessage,
} from "../../helpers/functions";

async function _handleError(error, dispatch) {
	var status = 504;

	if (!isEmptyObject(error.response)) {
		status = error.response.status;
	}

	if (status === 403) {
		await dispatch({
			type: FETCH_USER_LOGOUT,
			error: error.response.data.message,
		});
		showToastMessage(error.response.data.message, "E");
	} else if (status === 400) {
		showToastMessage(error.response.data.message, "E");
		await dispatch({
			type: FETCH_USER_FAILURE,
			error: error.response.data.message,
		});
	} else {
		showToastMessage(error.message, "E");
		await dispatch({
			type: FETCH_USER_FAILURE,
			error: error.message,
		});
	}
}

export const signUpCompany = (signUpData) => async (dispatch) => {
	const sessionData = await sessionInfo();

	if (localStorage.fooventLoggedIn) {
		setAuthToken(sessionData.token);
	}

	dispatch({
		type: ADD_COMPANY_REQUEST,
	});

	try {
		const response = await axios.post(SINGUP_COMPANY, signUpData);

		console.log(response);
		dispatch({
			type: ADD_COMPANY_SUCCESS,
		});
	} catch (err) {
		dispatch({
			type: ADD_COMPANY_FAILURE,
			error: err.message,
		});

		_handleError(err, dispatch);
	}
};

export const getAllCompanies = () => async (dispatch) => {
	const sessionData = await sessionInfo();

	if (localStorage.fooventLoggedIn) {
		setAuthToken(sessionData.token);
	}

	dispatch({
		type: FETCH_COMPANY_REQUEST,
	});

	try {
		const response = await axios.get(GET_ALL_COMPANY);

		dispatch({
			type: FETCH_COMPANY_SUCCESS,
			payload: response.data.result,
		});
	} catch (err) {
		dispatch({
			type: FETCH_COMPANY_FAILURE,
			error: err.message,
		});

		_handleError(err, dispatch);
	}
};

export const getCompaniesCount = () => async (dispatch) => {
	const sessionData = await sessionInfo();

	if (localStorage.fooventLoggedIn) {
		setAuthToken(sessionData.token);
	}

	dispatch({
		type: FETCH_COMPANY_REQUEST,
	});

	try {
		const response = await axios.get(GET_COMPANY_COUNT);

		dispatch({
			type: FETCH_COMPANY_COUNT_SUCCESS,
			payload: response.data.result,
		});
	} catch (err) {
		dispatch({
			type: FETCH_COMPANY_FAILURE,
			error: err.message,
		});
		_handleError(err, dispatch);
	}
};

// getting all the user and customers

export const getAllCaterUsers = () => async (dispatch) => {
	const sessionData = await sessionInfo();

	if (localStorage.fooventLoggedIn) {
		setAuthToken(sessionData.token);
	}

	dispatch({
		type: FETCH_CATER_USER_REQUEST,
	});

	try {
		const response = await axios.get(GET_ALL_USER);

		dispatch({
			type: FETCH_CATER_USER_SUCCESS,
			payload: response.data.result,
		});
	} catch (err) {
		dispatch({
			type: FETCH_CATER_USER_FAILURE,
			error: err.message,
		});

		_handleError(err, dispatch);
	}
};

export const getAllCaterCustomers = () => async (dispatch) => {
	const sessionData = await sessionInfo();

	if (localStorage.fooventLoggedIn) {
		setAuthToken(sessionData.token);
	}

	dispatch({
		type: FETCH_CUSTOMER_REQUEST,
	});

	try {
		const response = await axios.get(GET_ALL_CUSTOMER);

		dispatch({
			type: FETCH_CUSTOMER_SUCCESS,
			payload: response.data.result,
		});
	} catch (err) {
		dispatch({
			type: FETCH_CUSTOMER_FAILURE,
			error: err.message,
		});

		_handleError(err, dispatch);
	}
};

// EVA MEMBERS
export const getAllEvaMembers = () => async (dispatch) => {
	const sessionData = await sessionInfo();

	if (localStorage.fooventLoggedIn) {
		setAuthToken(sessionData.token);
	}

	dispatch({
		type: FETCH_EVA_MEMBER_REQUEST,
	});

	try {
		const response = await axios.get(GET_ALL_EVA_MEMBER);

		dispatch({
			type: FETCH_EVA_MEMBER_SUCCESS,
			payload: response.data.result,
		});
	} catch (err) {
		dispatch({
			type: FETCH_EVA_MEMBER_FAILURE,
			error: err.message,
		});

		_handleError(err, dispatch);
	}
};

// BILLING COMPANY
export const getAllBillingCompanies = () => async (dispatch) => {
	const sessionData = await sessionInfo();

	if (localStorage.fooventLoggedIn) {
		setAuthToken(sessionData.token);
	}

	dispatch({
		type: FETCH_BILLING_COMPANY_REQUEST,
	});

	try {
		const response = await axios.get(GET_ALL_BILLING_COMPANY);

		dispatch({
			type: FETCH_BILLING_COMPANY_SUCCESS,
			payload: response.data.result,
		});
	} catch (err) {
		dispatch({
			type: FETCH_BILLING_COMPANY_FAILURE,
			error: err.message,
		});

		_handleError(err, dispatch);
	}
};

export const addBillingCompany = (billingCompanyInfo) => async (dispatch) => {
	const sessionData = await sessionInfo();

	if (localStorage.fooventLoggedIn) {
		setAuthToken(sessionData.token);
	}

	dispatch({
		type: ADD_BILLING_COMPANY_REQUEST,
	});

	try {
		await axios.post(ADD_BILLING_COMPANY, billingCompanyInfo);

		dispatch({
			type: ADD_BILLING_COMPANY_SUCCESS,
		});
	} catch (err) {
		dispatch({
			type: ADD_BILLING_COMPANY_FAILURE,
			error: err.message,
		});

		_handleError(err, dispatch);
	}
};
