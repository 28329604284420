import React from "react";
import "./Sidebar.scss";

// icons from material UI
import GroupIcon from "@mui/icons-material/Group";
import DashboardIcon from "@mui/icons-material/Dashboard";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import BackupTableIcon from "@mui/icons-material/BackupTable";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import CardMembershipIcon from "@mui/icons-material/CardMembership";

import { Link } from "react-router-dom";

const SideNavigation = () => {
	// const [toggle, setToggle] = useState(true);
	// function toggleSidebar() {
	// 	let sideNav = document.getElementById("sideNavWrapper");
	// 	if (toggle) {
	// 		sideNav.style.width = "250px";
	// 		setToggle(false);
	// 	} else {
	// 		sideNav.style.width = "70px";
	// 		setToggle(true);
	// 	}
	// }

	return (
		<div className="side-navigation-wrapper" id="sideNavWrapper">
			<div className="side-menu-container">
				<Link to="/dashboard">
					<div className="menu-container">
						<DashboardIcon className="menu-icon" />
						<span className="menu-label">Dashboard</span>
					</div>
				</Link>
				<Link to="/winloss">
					<div className="menu-container">
						<BackupTableIcon className="menu-icon" />
						<span className="menu-label">Win Loss</span>
					</div>
				</Link>
				<Link to="/caterPackage">
					<div className="menu-container">
						<SubscriptionsIcon className="menu-icon" />
						<span className="menu-label">Cater Package</span>
					</div>
				</Link>
				<Link to="/caterSubscription">
					<div className="menu-container">
						<CardMembershipIcon className="menu-icon" />
						<span className="menu-label">Cater Subscription</span>
					</div>
				</Link>
				<Link to="/evaMember">
					<div className="menu-container">
						<LocationCityIcon className="menu-icon" />
						<span className="menu-label">Eva Members</span>
					</div>
				</Link>
				<Link to="/add/company">
					<div className="menu-container">
						<AddBusinessIcon className="menu-icon" />
						<span className="menu-label">Add Cater Company</span>
					</div>
				</Link>
				<Link to="/company">
					<div className="menu-container">
						<LocationCityIcon className="menu-icon" />
						<span className="menu-label">Cater Company</span>
					</div>
				</Link>
				<Link to="/users">
					<div className="menu-container">
						<GroupIcon className="menu-icon" />
						<span className="menu-label">System Users</span>
					</div>
				</Link>
				<Link to="/customers">
					<div className="menu-container">
						<Diversity3Icon className="menu-icon" />
						<span className="menu-label">Customers</span>
					</div>
				</Link>
				<Link to="/support">
					<div className="menu-container">
						<SupportAgentIcon className="menu-icon" />
						<span className="menu-label">Support Issues</span>
					</div>
				</Link>
				{/* billing */}
				<Link to="/add/billing/company">
					<div className="menu-container">
						<LocationCityIcon className="menu-icon" />
						<span className="menu-label">Add Billing Company</span>
					</div>
				</Link>
				<Link to="/billing/company">
					<div className="menu-container">
						<LocationCityIcon className="menu-icon" />
						<span className="menu-label">Billing Company</span>
					</div>
				</Link>
			</div>
		</div>
	);
};
export default SideNavigation;
