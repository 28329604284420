import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";

import "../Company/addCompany.style.scss";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

// components imported
import {
	genderOption,
	countryOption,
	cityOption,
	provinceOption,
	districtOption,
	registrationDocument,
} from "../../constants/variables";
import SelectBox from "../../components/select-box/select-box.input";
import TextInput from "../../components/text-input/text-input-error.component";

// actions
import { addBillingCompany } from "../../redux/actions/company-action";

const companySchema = yup.object().shape({
	company_name: yup.string().required("company name required"),
	company_type: yup.string().required("business type required"),
	company_email: yup
		.string()
		.required("company email is required")
		.email("Please enter a valid email"),
	company_mobile: yup
		.number()
		.required("company mobile is required")
		.typeError("company mobile is required"),
	company_phone: yup
		.number()
		.required("company phone is required")
		.typeError("company phone is required"),
	company_address: yup
		.string()
		.required("company address is required")
		.typeError("company address is required"),
	company_facebook: yup.string(),
	company_website: yup.string(),
	company_province: yup
		.string()
		.matches(/^([^0-9]*)$/, "No numbers allowed")
		.required("company province is required")
		.typeError("Please enter a valid province"),
	company_district: yup
		.string()
		.matches(/^([^0-9]*)$/, "No numbers allowed")
		.required("company district is required")
		.typeError("company district  is required"),
	company_city: yup
		.string()
		.matches(/^([^0-9]*)$/, "No numbers allowed")
		.required("company city is required")
		.typeError("company city is required"),
	company_country: yup
		.string()
		.required("company country is required")
		.typeError("company country is required"),
});

const userSchema = yup.object().shape({
	first_name: yup
		.string()
		.matches(/^([^0-9]*)$/, "No numbers allowed")
		.required("required"),
	last_name: yup
		.string()
		.required("required")
		.matches(/^([^0-9]*)$/, "No numbers allowed"),
	user_mobile: yup
		.number()
		.typeError("Must be a number")
		.required("required"),
	user_email: yup
		.string()
		.required("required")
		.email("Please enter a valid email"),
	password: yup
		.string()
		.required("required")
		.min(8, "Min characters should be 8"),
	confirm_password: yup
		.string()
		.oneOf([yup.ref("password"), null], "Passwords must match")
		.required("required"),
	user_gender: yup.string().required("required"),
	user_address: yup.string().required("required"),
	user_city: yup
		.string()
		.matches(/^([^0-9]*)$/, "No numbers allowed")
		.required("required"),
	user_country: yup
		.string()
		.matches(/^([^0-9]*)$/, "No numbers allowed")
		.required("required"),
});

const companyDocumentSchema = yup.object().shape({
	username: yup.string().required("username is required"),
	ird_password: yup.string().required("required"),
	registration_type: yup
		.string()
		.typeError("registration document number is required")
		.required("registration document number is required"),
	registration_number: yup.string().required("required"),
});

// demo data
// {
// company_country: "nepal",
// company_city: "bharatpur",
// company_district: "Banke",
// company_province: "Sudurpashchim Province",
// company_website: "www.testbilling.com",
// company_facebook: "www.facebook.com/testbiling",
// company_address: "company address",
// company_phone: 14415212,
// company_mobile: 9872612131,
// company_email: "sarak@yopmail.com",
// company_type: "stationary",
// company_name: "Test Stationary",
// user_country: "nepal",
// user_city: "birgunj",
// user_address: "teku",
// user_gender: "male",
// confirm_password: "1234567890",
// password: "1234567890",
// user_email: "sarak@yopmail.com",
// user_mobile: 9843524152,
// last_name: "sarak",
// first_name: "user",
// username: "sarak",
// ird_password: "1234567890",
// registration_type: "pan",
// registration_number: "12123123",
// }

export default function AddBillingCompany() {
	const dispatch = useDispatch();
	const history = useHistory();
	// displaying control state
	const [companyFormState, setCompanyFormState] = useState(true);
	const [userFormState, setUserFormState] = useState(false);
	const [companyDocumentFormState, setCompanyDocumentFormState] =
		useState(false);
	const [showPreview, setShowPreview] = useState(false);

	// form submission state
	const [billingCompanyState, setBillingCompanyState] = useState({});

	// company form control
	const {
		control: companyControl,
		handleSubmit: handleCompanySubmit,
		formState: { errors: companyErrors },
		reset: companyReset,
		// register: companyRegister,
		// watch: companyWatch,
	} = useForm({
		defaultValues: {},
		mode: "onBlur",
		resolver: yupResolver(companySchema),
	});

	// user form control
	const {
		control: userControl,
		handleSubmit: handleUserSubmit,
		formState: { errors: userErrors },
		reset: userReset,
		// register: userRegister,
	} = useForm({
		defaultValues: {},
		mode: "onBlur",
		resolver: yupResolver(userSchema),
	});

	// subscription form control
	const {
		control: companyDocumentControl,
		handleSubmit: handleCompanyDocumentSubmit,
		formState: { errors: companyDocumentErrors },
		reset: companyDocumentReset,
	} = useForm({
		defaultValues: {},
		mode: "onBlur",
		resolver: yupResolver(companyDocumentSchema),
	});

	useEffect(() => {}, [dispatch]);

	// handling view
	const _companyTab = () => {
		setCompanyFormState(true);
		setUserFormState(false);
		setCompanyDocumentFormState(false);
		setShowPreview(false);
	};
	const _userTab = () => {
		setCompanyFormState(false);
		setUserFormState(true);
		setCompanyDocumentFormState(false);
		setShowPreview(false);
	};
	const _companyDocumentTab = () => {
		setCompanyFormState(false);
		setUserFormState(false);
		setCompanyDocumentFormState(true);
		setShowPreview(false);
	};
	const _previewTab = () => {
		setCompanyFormState(false);
		setUserFormState(false);
		setCompanyDocumentFormState(false);
		setShowPreview(true);
	};

	// handling form
	const _companyFormSubmit = (data) => {
		setBillingCompanyState({ ...billingCompanyState, ...data });
		setCompanyFormState(false);
		setUserFormState(true);
	};

	const _userFormSubmit = (data) => {
		setBillingCompanyState({ ...billingCompanyState, ...data });
		setUserFormState(false);
		setCompanyDocumentFormState(true);
	};

	const _companyDocumentFormSubmit = (data) => {
		setBillingCompanyState({ ...billingCompanyState, ...data });
		setCompanyDocumentFormState(false);
		setShowPreview(true);
	};

	const _signUp = () => {
		dispatch(addBillingCompany(billingCompanyState));
		companyReset();
		userReset();
		companyDocumentReset();

		// going to first form
		setShowPreview(false);
		setCompanyFormState(true);
	};

	// displaying form
	const companyForm = () => {
		return (
			<form
				key={1}
				style={{ flex: 1 }}
				onSubmit={handleCompanySubmit(_companyFormSubmit)}
			>
				<div className="form-main-content bg-default">
					<div className="form-group">
						<div className="form-input-container">
							<Controller
								name="company_name"
								control={companyControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small companyName"
										label="Company Name"
										type="text"
										field={field}
										errormessage={
											companyErrors?.company_name?.message
										}
									/>
								)}
							/>
							<Controller
								name="company_type"
								control={companyControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small companyType"
										label="Business Type"
										type="text"
										field={field}
										errormessage={
											companyErrors?.company_type?.message
										}
									/>
								)}
							/>
							<Controller
								name="company_email"
								control={companyControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small companyEmail"
										label="Company Email"
										type="email"
										field={field}
										errormessage={
											companyErrors?.company_email
												?.message
										}
									/>
								)}
							/>
							<Controller
								name="company_mobile"
								control={companyControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small companyMobile"
										label="Company Mobile"
										type="number"
										field={field}
										errormessage={
											companyErrors?.company_mobile
												?.message
										}
									/>
								)}
							/>
							<Controller
								name="company_phone"
								control={companyControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small companyPhone"
										label="Company Phone"
										type="number"
										field={field}
										errormessage={
											companyErrors?.company_phone
												?.message
										}
									/>
								)}
							/>
						</div>
						<div className="form-input-container">
							<Controller
								name="company_address"
								control={companyControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small companyAddress"
										label="Company Address"
										type="text"
										field={field}
										errormessage={
											companyErrors?.company_address
												?.message
										}
									/>
								)}
							/>
							<Controller
								name="company_website"
								control={companyControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										id="outlined-size-small companyWebsite"
										label="Company Website"
										type="text"
										field={field}
										errormessage={
											companyErrors?.company_website
												?.message
										}
									/>
								)}
							/>

							<Controller
								name="company_facebook"
								control={companyControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										id="outlined-size-small companyFacebook"
										label="Company Facebook"
										type="text"
										field={field}
										errormessage={
											companyErrors?.company_facebook
												?.message
										}
									/>
								)}
							/>
						</div>
						<div className="form-input-container">
							<Controller
								name="company_province"
								control={companyControl}
								defaultValue=""
								render={({ field }) => (
									<SelectBox
										id="outlined-size-small companyProvince"
										className="search"
										label="Province"
										field={field}
										size="small"
										options={provinceOption}
										required
										errormessage={
											companyErrors?.company_province
												?.message
										}
									/>
								)}
							/>
							<Controller
								name="company_district"
								control={companyControl}
								defaultValue=""
								render={({ field }) => (
									<SelectBox
										id="outlined-size-small companyDistrict"
										className="search"
										label="District"
										field={field}
										size="small"
										options={districtOption}
										required
										errormessage={
											companyErrors?.company_district
												?.message
										}
									/>
								)}
							/>
							<Controller
								name="company_city"
								control={companyControl}
								defaultValue=""
								render={({ field }) => (
									<SelectBox
										id="outlined-size-small companyCity"
										className="search"
										label="City"
										field={field}
										size="small"
										options={cityOption}
										required
										errormessage={
											companyErrors?.company_city?.message
										}
									/>
								)}
							/>
							<Controller
								name="company_country"
								control={companyControl}
								defaultValue=""
								render={({ field }) => (
									<SelectBox
										id="outlined-size-small companyCountry"
										className="search"
										label="Country"
										field={field}
										size="small"
										options={countryOption}
										required
										errormessage={
											companyErrors?.company_country
												?.message
										}
									/>
								)}
							/>
						</div>
					</div>

					<div className="company-action-btn">
						<button
							className="btn btn-outline-secondary"
							type="submit"
						>
							Next {">"}
						</button>
					</div>
				</div>
			</form>
		);
	};

	const userForm = () => {
		return (
			<form
				key={3}
				style={{ flex: 1 }}
				onSubmit={handleUserSubmit(_userFormSubmit)}
			>
				<div className="form-main-content bg-default">
					<div className="form-group">
						<div className="form-input-container">
							<Controller
								name="first_name"
								control={userControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small firstName"
										label="First Name"
										type="text"
										field={field}
										errormessage={
											userErrors?.first_name?.message
										}
									/>
								)}
							/>
							<Controller
								name="last_name"
								control={userControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small lastName"
										label="Last Name"
										type="text"
										field={field}
										errormessage={
											userErrors?.last_name?.message
										}
									/>
								)}
							/>
							<Controller
								name="user_mobile"
								control={userControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small userMobile"
										label="Mobile Number"
										type="number"
										field={field}
										errormessage={
											userErrors?.user_mobile?.message
										}
									/>
								)}
							/>
							<Controller
								name="user_email"
								control={userControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small userEmail"
										label="User Email"
										type="email"
										field={field}
										errormessage={
											userErrors?.user_email?.message
										}
									/>
								)}
							/>
							<Controller
								name="password"
								control={userControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small userPassword"
										label="Password"
										type="password"
										field={field}
										errormessage={
											userErrors?.password?.message
										}
									/>
								)}
							/>
							<Controller
								name="confirm_password"
								control={userControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small confirmPassword"
										label="Confirm Password"
										type="password"
										field={field}
										errormessage={
											userErrors?.confirm_password
												?.message
										}
									/>
								)}
							/>
						</div>
						<div className="form-input-container">
							<Controller
								name="user_gender"
								control={userControl}
								defaultValue=""
								render={({ field }) => (
									<SelectBox
										id="outlined-size-small package_type"
										className="search"
										label="Gender"
										field={field}
										size="small"
										options={genderOption}
										required
										errormessage={
											userErrors?.user_gender?.message
										}
									/>
								)}
							/>
							<Controller
								name="user_address"
								control={userControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small userAddress"
										label="Address"
										type="text"
										field={field}
										errormessage={
											userErrors?.user_address?.message
										}
									/>
								)}
							/>
							<Controller
								name="user_city"
								control={userControl}
								defaultValue=""
								render={({ field }) => (
									<SelectBox
										id="outlined-size-small userCity"
										className="search"
										label="City"
										field={field}
										size="small"
										options={cityOption}
										required
										errormessage={
											userErrors?.user_city?.message
										}
									/>
								)}
							/>
							<Controller
								name="user_country"
								control={userControl}
								defaultValue=""
								render={({ field }) => (
									<SelectBox
										id="outlined-size-small userCountry"
										className="search"
										label="Country"
										field={field}
										size="small"
										options={countryOption}
										required
										errormessage={
											userErrors?.user_country?.message
										}
									/>
								)}
							/>
						</div>
					</div>

					<div className="user-action-btn">
						<button
							className="btn btn-outline-secondary"
							type="button"
							onClick={() => {
								setUserFormState(false);
							}}
						>
							{"< "}Back
						</button>
						<button
							className="btn btn-outline-secondary"
							type="submit"
						>
							Next {">"}
						</button>
					</div>
				</div>
			</form>
		);
	};

	const companyDocumentForm = () => {
		return (
			<form
				key={2}
				style={{ flex: 1 }}
				onSubmit={handleCompanyDocumentSubmit(
					_companyDocumentFormSubmit
				)}
			>
				<div className="form-main-content bg-default">
					<div className="form-group">
						<div className="form-input-container">
							<Controller
								name="username"
								control={companyDocumentControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small username"
										label="IRD Username"
										type="text"
										field={field}
										errormessage={
											companyDocumentErrors?.username
												?.message
										}
									/>
								)}
							/>
							<Controller
								name="ird_password"
								control={companyDocumentControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small irdPassword"
										label="IRD Password"
										type="password"
										field={field}
										errormessage={
											companyDocumentErrors?.ird_password
												?.message
										}
									/>
								)}
							/>
							<Controller
								name="registration_type"
								control={companyDocumentControl}
								defaultValue=""
								render={({ field }) => (
									<SelectBox
										id="outlined-size-small registrationDocument"
										className="search"
										label="Registration Document"
										field={field}
										size="small"
										options={registrationDocument}
										required
										errormessage={
											userErrors?.registration_type
												?.message
										}
									/>
								)}
							/>
							<Controller
								name="registration_number"
								control={companyDocumentControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small documentNumber"
										label="Document Number"
										type="number"
										field={field}
										errormessage={
											companyDocumentErrors
												?.registration_number?.message
										}
									/>
								)}
							/>
						</div>
					</div>
					<div className="user-action-btn">
						<button
							className="btn btn-outline-secondary"
							type="button"
							onClick={() => {
								setUserFormState(true);
								setCompanyDocumentFormState(false);
							}}
						>
							{"< "}Back
						</button>
						<button
							className="btn btn-outline-secondary"
							type="submit"
						>
							Preview {">"}
						</button>
					</div>
				</div>
			</form>
		);
	};

	// displaying data
	const companyInfo = useMemo(() => {
		return [
			{
				label: "Company Name",
				value: billingCompanyState.company_name,
			},
			{
				label: "Business Type",
				value: billingCompanyState.company_type,
			},
			{
				label: "Email",
				value: billingCompanyState.company_email,
			},
			{
				label: "Mobile",
				value: billingCompanyState.company_mobile,
			},
			{
				label: "Phone",
				value: billingCompanyState.company_phone,
			},
			{
				label: "Website",
				value: billingCompanyState.company_website || "-",
			},
			{
				label: "Facebook",
				value: billingCompanyState.company_facebook || "-",
			},
			{
				label: "Province",
				value: billingCompanyState.company_province,
			},
			{
				label: "District",
				value: billingCompanyState.company_district,
			},
			{
				label: "City",
				value: billingCompanyState.company_city,
			},
			{
				label: "Country",
				value: billingCompanyState.company_country,
			},
			{
				label: "Address",
				value: billingCompanyState.company_address,
			},
		];
	}, [billingCompanyState]);

	const userInfo = useMemo(() => {
		return [
			{
				label: "First Name",
				value: billingCompanyState.first_name,
			},
			{
				label: "Last Name",
				value: billingCompanyState.last_name,
			},
			{
				label: "Gender",
				value: billingCompanyState.user_gender,
			},
			{
				label: "Mobile",
				value: billingCompanyState.user_mobile,
			},
			{
				label: "Email",
				value: billingCompanyState.user_email,
			},
			{
				label: "Address",
				value: billingCompanyState.user_address,
			},
			{
				label: "City",
				value: billingCompanyState.user_city,
			},
			{
				label: "Country",
				value: billingCompanyState.user_country,
			},
		];
	}, [billingCompanyState]);

	const companyDocumentInfo = useMemo(() => {
		return [
			{
				label: "IRD Username",
				value: billingCompanyState.username,
			},
			{
				label: "IRD Password",
				value: billingCompanyState.ird_password,
			},
			{
				label: "Registration Document",
				value: billingCompanyState.registration_type,
			},
			{
				label: "Registration Document Number",
				value: billingCompanyState.registration_number,
			},
		];
	}, [billingCompanyState]);

	return (
		<>
			{
				<div className="signup-main">
					<div className="signup-phase">
						<div
							className="company-form"
							onClick={() => {
								_companyTab();
							}}
						>
							Company Information
						</div>
						<div
							className="user-form"
							onClick={() => {
								_userTab();
							}}
						>
							User Information
						</div>
						<div
							className="user-form"
							onClick={() => {
								_companyDocumentTab();
							}}
						>
							Company Document Info
						</div>
						<div
							className="preview-form"
							onClick={() => {
								_previewTab();
							}}
						>
							Preview
						</div>
					</div>

					{companyFormState && companyForm()}
					{userFormState && userForm()}
					{companyDocumentFormState && companyDocumentForm()}

					{showPreview && (
						<div className="preview-data-main">
							<div className="preview-company">
								<div className="section-header">
									<h2>Company Information</h2>
									<div
										className="form-edit-button"
										onClick={() => _companyTab()}
									>
										Edit
									</div>
								</div>
								<div className="section-body">
									{companyInfo.map((info, index) => (
										<div key={index} className="form-data">
											<div className="data-label">
												{info.label}
											</div>
											<div className="data-value">
												{info.value}
											</div>
										</div>
									))}
								</div>
							</div>

							<div className="preview-user">
								<div className="section-header">
									<h2>User Information</h2>
									<div
										className="form-edit-button"
										onClick={() => _userTab()}
									>
										Edit
									</div>
								</div>
								<div className="section-body">
									{userInfo.map((info, index) => (
										<div key={index} className="form-data">
											<div className="data-label">
												{info.label}
											</div>
											<div className="data-value">
												{info.value}
											</div>
										</div>
									))}
								</div>
							</div>

							<div className="preview-user">
								<div className="section-header">
									<h2>Company Document Information</h2>
									<div
										className="form-edit-button"
										onClick={() => _companyDocumentTab()}
									>
										Edit
									</div>
								</div>
								<div className="section-body">
									{companyDocumentInfo.map((info, index) => (
										<div key={index} className="form-data">
											<div className="data-label">
												{info.label}
											</div>
											<div className="data-value">
												{info.value}
											</div>
										</div>
									))}
								</div>
							</div>

							<div>
								<button
									className="btn btn-outline-secondary text-uppercase"
									onClick={() => _signUp()}
								>
									Sign Up Company
								</button>
							</div>
						</div>
					)}
				</div>
			}
		</>
	);
}
