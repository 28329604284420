import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./customer.style.scss";
import TtyIcon from "@mui/icons-material/Tty";
import MailIcon from "@mui/icons-material/Mail";
import CakeIcon from "@mui/icons-material/Cake";
import Person3Icon from "@mui/icons-material/Person3";
import Person4Icon from "@mui/icons-material/Person4";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";

import { getAllCaterCustomers } from "../../redux/actions/company-action";

export default function Customer() {
	const dispatch = useDispatch();
	const caterCustomerData = useSelector(
		(state) => state.company.caterCustomers
	);

	useEffect(() => {
		dispatch(getAllCaterCustomers());
	}, [dispatch]);

	return (
		<div className="customer-container">
			<div className="customer-container-inner">
				{caterCustomerData.map((customer, index) => (
					<div key={index} className="user-item-container">
						<div className="item-img-container">
							{customer.customer_image ? (
								<img
									src={customer.customer_image}
									alt="customer"
								/>
							) : customer.customer_gender !== "other" ? (
								customer.customer_gender === "male" ? (
									<Person4Icon className="customer-icon" />
								) : (
									<Person3Icon className="customer-icon" />
								)
							) : customer.customer_type === "business" ? (
								<LocationCityIcon className="customer-icon" />
							) : (
								<SelfImprovementIcon className="customer-icon" />
							)}
							<div className="user-verified active">
								<CheckCircleIcon className="menu-icon" />
							</div>
						</div>
						<div className="item-details-container">
							<div>
								<h4 className="item-mainText">
									{customer?.customer_id}
									{". "}
									{customer?.customer_fname}{" "}
									{customer?.customer_lname}
								</h4>
								<div className="details-divider" />
							</div>
							<div style={{ flex: 1 }}>
								<div className="item-info">
									<div className="info-icon a">
										<TtyIcon className="menu-icon" />
									</div>
									<div className="info-details">
										<p className="subText">Contact</p>
										<p className="mainText">
											{customer?.customer_mobile}
										</p>
									</div>
								</div>
								{customer?.customer_email && (
									<div className="item-info">
										<div className="info-icon b">
											<MailIcon className="menu-icon" />
										</div>
										<div className="info-details">
											<p className="subText">Email</p>
											<p className="mainText">
												{customer?.customer_email}
											</p>
										</div>
									</div>
								)}

								<div className="item-info">
									<div className="info-icon c">
										<CakeIcon className="menu-icon" />
									</div>
									<div className="info-details">
										<p className="subText">Date of Birth</p>
										<p className="mainText">
											{customer.customer_dob}
										</p>
									</div>
								</div>

								<div className="item-info">
									<div className="info-icon c">
										<LocationOnIcon className="menu-icon" />
									</div>
									<div className="info-details">
										<p className="subText">Location</p>
										<p className="mainText">
											{customer.customer_address},{" "}
											{customer.customer_city}
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	);
}
