import "./App.scss";
// setting up the redux
import { Provider } from "react-redux";
import store from "./redux/store";
import { Router, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import history from "./helpers/history";
import ProtectedRoute from "./routes/ProtectedRoute";

import Login from "./pages/Login/login.page.jsx";
import Dashboard from "./pages/Dashboard/dashboard.page";
import Company from "./pages/Company/company.page.jsx";
import EvaMember from "./pages/EvaMember/evaMember.page";
import User from "./pages/User/user.page.jsx";
import Customer from "./pages/Customer/customer.page.jsx";

import AddCompany from "./pages/Company/addCompany.page.jsx";
import Winloss from "./pages/Winloss/winloss.page.jsx";

import SupportIssuesPage from "./pages/Support/support-issues.page";
import CaterPackage from "./pages/CaterPackage/cater-package.page";
import CaterSubscription from "./pages/CaterSubscription/cater-subscription.page";
import AddBillingCompany from "./pages/BillingCompany/addBillingCompany.page";
import BillingCompany from "./pages/BillingCompany/billingCompany.page";

function App() {
	return (
		<Provider store={store}>
			<div className="App">
				<Router history={history}>
					<Switch>
						<Route exact path="/" component={Login} />
						<ProtectedRoute
							exact
							path="/dashboard"
							component={Dashboard}
							navigation={true}
						/>
						<ProtectedRoute
							exact
							path="/company"
							component={Company}
							navigation={true}
						/>
						<ProtectedRoute
							exact
							path="/evaMember"
							component={EvaMember}
							navigation={true}
						/>
						<ProtectedRoute
							exact
							path="/add/company"
							component={AddCompany}
							navigation={true}
						/>
						<ProtectedRoute
							exact
							path="/users"
							component={User}
							navigation={true}
						/>
						<ProtectedRoute
							exact
							path="/customers"
							component={Customer}
							navigation={true}
						/>
						<ProtectedRoute
							exact
							path="/winloss"
							component={Winloss}
							navigation={true}
						/>
						<ProtectedRoute
							exact
							path="/support"
							component={SupportIssuesPage}
							navigation={true}
						/>
						<ProtectedRoute
							exact
							path="/caterPackage"
							component={CaterPackage}
							navigation={true}
						/>
						<ProtectedRoute
							exact
							path="/caterSubscription"
							component={CaterSubscription}
							navigation={true}
						/>

						{/* billing */}
						<ProtectedRoute
							exact
							path="/billing/company"
							component={BillingCompany}
							navigation={true}
						/>
						<ProtectedRoute
							exact
							path="/add/billing/company"
							component={AddBillingCompany}
							navigation={true}
						/>
					</Switch>
				</Router>
				<ToastContainer
					position="top-right"
					autoClose={5000}
					hideProgressBar
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable={false}
					pauseOnHover
				/>
			</div>
		</Provider>
	);
}

export default App;
