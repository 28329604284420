import {
	// FETCH
	FETCH_CATER_PACKAGE_REQUEST,
	FETCH_CATER_PACKAGE_SUCCESS,
	FETCH_CATER_PACKAGE_FAILURE,
	// ADD
	ADD_CATER_PACKAGE_REQUEST,
	ADD_CATER_PACKAGE_SUCCESS,
	ADD_CATER_PACKAGE_FAILURE,
	// UPDATE
	UPDATE_CATER_PACKAGE_REQUEST,
	UPDATE_CATER_PACKAGE_SUCCESS,
	UPDATE_CATER_PACKAGE_FAILURE,
} from "../action-types";

const initialState = {
	caterPackageList: [],
	isLoading: false,
	error: "",
};

const caterPackageReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case FETCH_CATER_PACKAGE_REQUEST:
		case ADD_CATER_PACKAGE_REQUEST:
		case UPDATE_CATER_PACKAGE_FAILURE:
			return {
				...state,
				isLoading: true,
			};
		case FETCH_CATER_PACKAGE_SUCCESS:
		case ADD_CATER_PACKAGE_SUCCESS:
		case UPDATE_CATER_PACKAGE_SUCCESS:
			return {
				...state,
				caterPackageList: payload,
				isLoading: false,
			};
		case FETCH_CATER_PACKAGE_FAILURE:
		case ADD_CATER_PACKAGE_FAILURE:
		case UPDATE_CATER_PACKAGE_REQUEST:
			return {
				...state,
				error: payload,
				isLoading: false,
			};
		default:
			return state;
	}
};

export default caterPackageReducer;
