import {
	// FETCH
	FETCH_CATER_SUBSCRIPTION_REQUEST,
	FETCH_CATER_SUBSCRIPTION_SUCCESS,
	FETCH_CATER_SUBSCRIPTION_FAILURE,
	// FETCH INDIVIDUAL
	FETCH_COMPANY_SUBSCRIPTION_REQUEST,
	FETCH_COMPANY_SUBSCRIPTION_SUCCESS,
	FETCH_COMPANY_SUBSCRIPTION_FAILURE,
	// ADD
	ADD_CATER_SUBSCRIPTION_REQUEST,
	ADD_CATER_SUBSCRIPTION_SUCCESS,
	ADD_CATER_SUBSCRIPTION_FAILURE,
	// UPDATE
	UPDATE_CATER_SUBSCRIPTION_REQUEST,
	UPDATE_CATER_SUBSCRIPTION_SUCCESS,
	UPDATE_CATER_SUBSCRIPTION_FAILURE,
	// DELETE
	DELETE_CATER_SUBSCRIPTION_REQUEST,
	DELETE_CATER_SUBSCRIPTION_SUCCESS,
	DELETE_CATER_SUBSCRIPTION_FAILURE,
	// USER
	FETCH_USER_LOGOUT,
} from "../action-types";

import axios from "axios";
import {
	GET_SUBCRIPTION_LIST,
	GET_SUBCRIPTION_BY_COMPANY,
	ADD_NEW_SUBSCRIPTION,
	UPDATE_SUBSCRIPTION,
	DELETE_SUBSCRIPTION,
} from "../../constants/endpoints";

import setAuthToken from "../../helpers/setAuthToken";

import {
	isEmptyObject,
	sessionInfo,
	showToastMessage,
} from "../../helpers/functions";

async function _handleError(error, dispatch) {
	var status = 504;

	if (!isEmptyObject(error.response)) {
		status = error.response.status;
	}

	if (status === 403) {
		// history.push({ pathname: "/" });dispatch();
		await dispatch({
			type: FETCH_USER_LOGOUT,
			error: error.response.data.message,
		});
		showToastMessage(error.response.data.message, "E");
	} else if (status === 400) {
		showToastMessage(error.response.data.message, "E");
		await dispatch({
			type: FETCH_CATER_SUBSCRIPTION_FAILURE,
			error: error.response.data.message,
		});
	} else {
		showToastMessage(error.message, "E");
		await dispatch({
			type: FETCH_CATER_SUBSCRIPTION_FAILURE,
			error: error.message,
		});
	}
}

export const getCaterSubscriptionList = () => async (dispatch) => {
	const sessionData = await sessionInfo();

	if (localStorage.fooventLoggedIn) {
		setAuthToken(sessionData.token);
	}

	dispatch({
		type: FETCH_CATER_SUBSCRIPTION_REQUEST,
	});

	await axios
		.get(GET_SUBCRIPTION_LIST)
		.then((response) => {
			if (response.data.success) {
				dispatch({
					type: FETCH_CATER_SUBSCRIPTION_SUCCESS,
					payload: response.data.result,
				});

				showToastMessage(response.data.message, "S");
			} else {
				showToastMessage(response.data.message, "E");
				dispatch({
					type: FETCH_CATER_SUBSCRIPTION_FAILURE,
					error: response.data.message,
				});
			}
		})
		.catch((err) => {
			_handleError(err, dispatch);
		});
};

export const getCaterSubscriptionByCompany =
	(company_info_id) => async (dispatch) => {
		const sessionData = await sessionInfo();

		if (localStorage.fooventLoggedIn) {
			setAuthToken(sessionData.token);
		}

		dispatch({
			type: FETCH_COMPANY_SUBSCRIPTION_REQUEST,
		});

		await axios
			.get(
				GET_SUBCRIPTION_BY_COMPANY +
					`?company_info_id=${company_info_id}`
			)
			.then((response) => {
				if (response.data.success) {
					dispatch({
						type: FETCH_COMPANY_SUBSCRIPTION_SUCCESS,
						payload: response.data.result,
					});

					showToastMessage(response.data.message, "S");
				} else {
					showToastMessage(response.data.message, "E");
					dispatch({
						type: FETCH_COMPANY_SUBSCRIPTION_FAILURE,
						error: response.data.message,
					});
				}
			})
			.catch((err) => {
				_handleError(err, dispatch);
			});
	};

export const addCaterSubscription =
	(newCaterSubscription) => async (dispatch) => {
		const sessionData = await sessionInfo();

		if (localStorage.fooventLoggedIn) {
			setAuthToken(sessionData.token);
		}

		dispatch({
			type: ADD_CATER_SUBSCRIPTION_REQUEST,
		});

		await axios
			.post(ADD_NEW_SUBSCRIPTION, newCaterSubscription)
			.then((response) => {
				if (response.data.success) {
					dispatch({
						type: ADD_CATER_SUBSCRIPTION_SUCCESS,
						payload: response.data.result,
					});

					showToastMessage(response.data.message, "S");
				} else {
					showToastMessage(response.data.message, "E");
					dispatch({
						type: ADD_CATER_SUBSCRIPTION_FAILURE,
						error: response.data.message,
					});
				}
			})
			.catch((err) => {
				_handleError(err, dispatch);
			});
	};

export const updateCaterSubscription =
	(caterSubscription) => async (dispatch) => {
		const sessionData = await sessionInfo();

		if (localStorage.fooventLoggedIn) {
			setAuthToken(sessionData.token);
		}

		dispatch({
			type: UPDATE_CATER_SUBSCRIPTION_REQUEST,
		});

		await axios
			.put(UPDATE_SUBSCRIPTION, caterSubscription)
			.then((response) => {
				if (response.data.success) {
					dispatch({
						type: UPDATE_CATER_SUBSCRIPTION_SUCCESS,
						payload: response.data.result,
					});

					showToastMessage(response.data.message, "S");
				} else {
					showToastMessage(response.data.message, "E");
					dispatch({
						type: UPDATE_CATER_SUBSCRIPTION_FAILURE,
						error: response.data.message,
					});
				}
			})
			.catch((err) => {
				_handleError(err, dispatch);
			});
	};

export const deleteCaterSubscription =
	(cater_subscription_id, company_info_id) => async (dispatch) => {
		const sessionData = await sessionInfo();

		if (localStorage.fooventLoggedIn) {
			setAuthToken(sessionData.token);
		}

		dispatch({
			type: DELETE_CATER_SUBSCRIPTION_REQUEST,
		});

		await axios
			.delete(
				DELETE_SUBSCRIPTION +
					`?cater_subscription_id=${cater_subscription_id}&company_info_id=${company_info_id}`
			)
			.then((response) => {
				if (response.data.success) {
					dispatch({
						type: DELETE_CATER_SUBSCRIPTION_SUCCESS,
						payload: response.data.result,
					});

					showToastMessage(response.data.message, "S");
				} else {
					showToastMessage(response.data.message, "E");
					dispatch({
						type: DELETE_CATER_SUBSCRIPTION_FAILURE,
						error: response.data.message,
					});
				}
			})
			.catch((err) => {
				_handleError(err, dispatch);
			});
	};
