import {
	FETCH_COMPANY_REQUEST,
	FETCH_COMPANY_SUCCESS,
	FETCH_COMPANY_FAILURE,
	FETCH_COMPANY_COUNT_SUCCESS,
	// user reducer
	FETCH_CATER_USER_REQUEST,
	FETCH_CATER_USER_SUCCESS,
	FETCH_CATER_USER_FAILURE,
	// customer reducer
	FETCH_CUSTOMER_REQUEST,
	FETCH_CUSTOMER_SUCCESS,
	FETCH_CUSTOMER_FAILURE,
	// eva nepal reducer
	FETCH_EVA_MEMBER_REQUEST,
	FETCH_EVA_MEMBER_SUCCESS,
	FETCH_EVA_MEMBER_FAILURE,
	// billing company  reducer
	FETCH_BILLING_COMPANY_REQUEST,
	FETCH_BILLING_COMPANY_SUCCESS,
	FETCH_BILLING_COMPANY_FAILURE,
	ADD_BILLING_COMPANY_REQUEST,
	ADD_BILLING_COMPANY_SUCCESS,
	ADD_BILLING_COMPANY_FAILURE,
} from "../action-types";

const initialState = {
	companies: [],
	caterUsers: [],
	caterCustomers: [],
	companyCount: {},
	evaNepalMembers: [],
	billingCompanies: [],
	loading: false,
	error: "",
};

const companyReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case FETCH_COMPANY_REQUEST:
			return {
				...state,
				loading: true,
			};
		case FETCH_COMPANY_SUCCESS:
			return {
				...state,
				companies: payload,
				loading: false,
			};
		case FETCH_COMPANY_COUNT_SUCCESS:
			return {
				...state,
				companyCount: payload,
				loading: false,
			};
		case FETCH_COMPANY_FAILURE:
			return {
				...state,
				error: payload,
				loading: false,
			};
		// cater users
		case FETCH_CATER_USER_REQUEST:
			return {
				...state,
				loading: true,
			};
		case FETCH_CATER_USER_SUCCESS:
			return {
				...state,
				caterUsers: payload,
				loading: false,
			};
		case FETCH_CATER_USER_FAILURE:
			return {
				...state,
				error: payload,
				loading: false,
			};
		// cater customers
		case FETCH_CUSTOMER_REQUEST:
			return {
				...state,
				loading: true,
			};
		case FETCH_CUSTOMER_SUCCESS:
			return {
				...state,
				caterCustomers: payload,
				loading: false,
			};
		case FETCH_CUSTOMER_FAILURE:
			return {
				...state,
				error: payload,
				loading: false,
			};
		// eva nepal and biling reducer
		case FETCH_EVA_MEMBER_REQUEST:
		case FETCH_BILLING_COMPANY_REQUEST:
		case ADD_BILLING_COMPANY_REQUEST:
			return {
				...state,
				loading: true,
			};
		case FETCH_EVA_MEMBER_SUCCESS:
			return {
				...state,
				evaNepalMembers: payload,
				loading: false,
			};
		case FETCH_BILLING_COMPANY_SUCCESS:
		case ADD_BILLING_COMPANY_SUCCESS:
			return {
				...state,
				billingCompanies: payload,
				loading: false,
			};
		case FETCH_EVA_MEMBER_FAILURE:
		case FETCH_BILLING_COMPANY_FAILURE:
		case ADD_BILLING_COMPANY_FAILURE:
			return {
				...state,
				error: payload,
				loading: false,
			};
		default:
			return state;
	}
};

export default companyReducer;
