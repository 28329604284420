import {
	FETCH_SUPPORT_REQUEST,
	FETCH_SUPPORT_SUCCESS,
	FETCH_SUPPORT_FAILURE,
	//update
	UPDATE_SUPPORT_REQUEST,
	UPDATE_SUPPORT_SUCCESS,
	UPDATE_SUPPORT_FAILURE,
} from "../action-types";

const initialState = {
	supportList: [],
	isLoading: false,
	error: "",
};

const supportReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case FETCH_SUPPORT_REQUEST:
			return {
				...state,
				isLoading: true,
			};
		case FETCH_SUPPORT_SUCCESS:
			return {
				...state,
				supportList: payload,
				isLoading: false,
			};
		case FETCH_SUPPORT_FAILURE:
			return {
				...state,
				error: payload,
				isLoading: false,
			};
		case UPDATE_SUPPORT_REQUEST:
			return {
				...state,
				isLoading: true,
			};
		case UPDATE_SUPPORT_SUCCESS:
			return {
				...state,
				supportList: payload,
				isLoading: false,
			};
		case UPDATE_SUPPORT_FAILURE:
			return {
				...state,
				error: payload,
				isLoading: false,
			};
		default:
			return state;
	}
};

export default supportReducer;
