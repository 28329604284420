import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useHistory } from "react-router-dom";

// import { loginUser } from "../../redux/index";

import "./login.style.scss";
import { isEmptyObject } from "../../helpers/functions";
import PasswordInput from "../../components/text-input/password-input-error.component";
import TextInput from "../../components/text-input/text-input-error.component";
import { Button } from "@mui/material";
import {
	loginUser,
	forgotPassword,
	resetPassword,
} from "../../redux/actions/login-action";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const loginSchema = yup.object().shape({
	user_email: yup
		.string()
		.required("Email cannot be empty")
		.email("Please enter a valid email"),
	password: yup
		.string()
		.required("Password Required")
		.min(8, "Password must be at least 8 Character"),
});

const forgotPasswordSchema = yup.object().shape({
	user_email: yup
		.string()
		.required("Email cannot be empty")
		.email("Please enter a valid email"),
});

const resetPasswordSchema = yup.object().shape({
	new_password: yup
		.string()
		.required("Password Required")
		.min(8, "Password must be at least 8 Character"),
	confirm_password: yup
		.string()
		.required("Password Required")
		.min(8, "Password must be at least 8 Character")
		.oneOf([yup.ref("new_password"), null], "Password must match"),
});

export default function Login(props) {
	const location = useLocation();
	const history = useHistory();
	const dispatch = useDispatch();

	const [forgotPasswordState, setForgotPassword] = useState(false);
	let parameterValues,
		verificationToken,
		forgotPasswordParam = false;

	if (location.search !== "") {
		parameterValues = location.search.split("?")[1].split("&");
		verificationToken = parameterValues[0].split("=")[1];
		forgotPasswordParam =
			parameterValues[2].split("=")[1] === "true" ? true : false;
	}

	const { user } = useSelector((state) => state.login);

	const {
		control,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm({
		resolver: !forgotPasswordParam
			? forgotPasswordState
				? yupResolver(forgotPasswordSchema)
				: yupResolver(loginSchema)
			: yupResolver(resetPasswordSchema),
	});

	// use effect section
	useEffect(() => {
		let isAuth = localStorage.getItem("fooventToken");
		if (isAuth) {
			history.push({
				pathname: "/dashboard",
			});
		}
	}, [history]);

	useEffect(() => {
		if (!isEmptyObject(user)) {
			history.push({
				pathname: "/dashboard",
			});
		}
	}, [history, user]);

	// api calling section
	const _userLogin = async (data) => {
		await dispatch(loginUser(data));
	};

	const _forgotPassword = async (data) => {
		await dispatch(forgotPassword({ user_email: data.user_email }));

		setForgotPassword(false);
		reset();
		history.push({
			pathname: "/",
		});
	};

	const _resetPassword = async (data) => {
		await dispatch(
			resetPassword({
				password: data.confirm_password,
				verificationToken,
			})
		);

		reset();
		history.push({
			pathname: "/",
		});
	};

	// rendering form
	const renderLoginForm = () => {
		return (
			<div className="login-form-container">
				<h2 className="login-title-message">Welcome Nibjar . . . .</h2>
				<label className="login-subtitle-message">
					Sign In to continue
				</label>
				<Controller
					name="user_email"
					control={control}
					defaultValue=""
					render={({ field }) => (
						<TextInput
							required
							id="outlined-size-small email"
							className="login-input"
							label="Email"
							type="email"
							field={field}
							errormessage={errors?.user_email?.message}
						/>
					)}
				/>
				<Controller
					name="password"
					control={control}
					defaultValue=""
					render={({ field }) => (
						<PasswordInput
							label="Password"
							field={field}
							errormessage={errors?.password?.message}
						/>
					)}
				/>
				{/* <span className="forgot-pw-container">
						Forgot Password?
					</span> */}

				<Button
					variant="contained"
					color="primary"
					className="login-button"
					onClick={handleSubmit(_userLogin)}
				>
					Login
				</Button>

				<span
					className="register-container"
					onClick={() => setForgotPassword(!forgotPasswordState)}
				>
					Forgot Password?
				</span>
			</div>
		);
	};

	const rendeForgotPasswordForm = () => {
		return (
			<div className="login-form-container">
				<h2 className="login-title-message">Forgot Password?</h2>
				<label className="login-subtitle-message">
					Did you forgot your password . . . .
				</label>
				<Controller
					name="user_email"
					control={control}
					defaultValue=""
					render={({ field }) => (
						<TextInput
							required
							id="outlined-size-small email"
							className="login-input"
							label="Email"
							type="email"
							field={field}
							errormessage={errors?.user_email?.message}
						/>
					)}
				/>

				<Button
					variant="contained"
					color="primary"
					className="login-button"
					onClick={handleSubmit(_forgotPassword)}
				>
					Send Reset Link
				</Button>

				<span
					className="register-container"
					onClick={() => setForgotPassword(!forgotPasswordState)}
				>
					Back to Login
				</span>
			</div>
		);
	};

	const rendeResetPasswordForm = () => {
		return (
			<div className="login-form-container">
				<h2 className="login-title-message">Reset Password</h2>
				<label className="login-subtitle-message">
					new and confirm password should match . . . .
				</label>
				<Controller
					name="new_password"
					control={control}
					defaultValue=""
					render={({ field }) => (
						<PasswordInput
							label="new password . . ."
							field={field}
							errormessage={errors?.new_password?.message}
						/>
					)}
				/>
				<Controller
					name="confirm_password"
					control={control}
					defaultValue=""
					render={({ field }) => (
						<PasswordInput
							label="confirm password . . ."
							field={field}
							errormessage={errors?.confirm_password?.message}
						/>
					)}
				/>

				<Button
					variant="contained"
					color="primary"
					className="login-button"
					onClick={handleSubmit(_resetPassword)}
				>
					Reset Password
				</Button>
			</div>
		);
	};

	return (
		<div className="login-wrapper">
			<div className="login-container">
				{forgotPasswordParam
					? rendeResetPasswordForm()
					: !forgotPasswordState
					? renderLoginForm()
					: rendeForgotPasswordForm()}
			</div>
		</div>
	);
}
