import moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import jwt_decode from "jwt-decode";
const { JWK, parse } = require("node-jose");

const decrypt = async (encryptedBody) => {
	let _privateKey = `-----BEGIN RSA PRIVATE KEY-----
MIICXAIBAAKBgHjDkiMKT8YN3KF8t3vwvL+kgPTQ462eM4MZxMYo0uJdnDgXsAVv
uXaGdXny6nRURnAC60bNKaui7NpLzv5v/f8INAP3NY9Wits/wVVySIHKOM/kmmO1
r2JedRYlqxHmM+lpa5Rux5QwI9B+pijY0+pT05N2RUcZzXRbgm1DHGlTAgMBAAEC
gYA3QTSCN9ucQQdsFAZX/SQ/g2NFoLYr4DCtkYLJMDCdRjuOJrhxJFd1dbwWmiDQ
LSc1ZQCFmqWwVJGOqBUDsfZCsFMpKapQsYwoIxVtFK5uZl0ZDbd8QWiIfBiVl7l8
vRpUwOyD1sfwq2Inl6Ca0P6fj/t/65b3EvdcSt9HKFOdQQJBANYhW7yqiFJNjxRt
2RwUGIka+2b9jJ0/scJdkQdNqCk2tnO4o1UkyvnUm36EZMPG219DzX+t+b+2AsoJ
aFmBAWMCQQCQYJtzJmLuNLYxL7rhVUDmdFopOadl+4g0rcMqSnTMLSVXLy/5lrOr
c5FTLmW7MSZBzFZ9oHLQcwEPvkptPvNRAkEAteofIpiKOcm0XXm5MhaMhaZLoMKV
PF6JaQPOmzLJQ0I9HPDmqgYY/sg11fUqGf0OD8/tfKRUAh/iHLyDT06+2wJAISDp
yoxeWwZhX/vxx87GK5s7LQZ7sNz8rgnJJKUdE9SwIsdDHORFqevExu94jHGEs8ht
eDoe3lHlRnWFone1YQJBANXseFaYbvAT+kk/7L0/+liVaUMrKe9Jn5Zniy0di0dM
88XVZo02fFv9UujNBBc9+CUzjMiwTQVKlD7y2SI50OA=
-----END RSA PRIVATE KEY-----
	`;

	let keystore = JWK.createKeyStore();
	await keystore.add(await JWK.asKey(_privateKey, "pem"));
	let outPut = parse.compact(encryptedBody);
	let decryptedVal = await outPut.perform(keystore);
	let claims = Buffer.from(decryptedVal.plaintext).toString();
	return claims;
};

export async function sessionInfo() {
	let encryptedToken = localStorage.getItem("fooventToken") || false;

	if (encryptedToken) {
		const jwtToken = await decrypt(encryptedToken);
		const token = jwtToken.replace(/"/g, "");
		const sessionData = await jwt_decode(jwtToken);

		// let s = jwt_decode(token); // old token

		return { ...sessionData, token };
	} else {
		return 0;
	}
}

export function isEmptyObject(obj) {
	for (var key in obj) {
		if (obj.hasOwnProperty(key)) return false;
	}
	return true;
}

export function showToastMessage(messageText, messageType = "I") {
	toast.dismiss();
	if (messageType === "S") {
		toast.success(messageText);
	}
	if (messageType === "I") {
		toast.info(messageText);
	}
	if (messageType === "E") {
		toast.error(messageText);
	}
	if (messageType === "W") {
		toast.warning(messageText);
	}
}

export function getDateDuration(startDate) {
	var now = moment(new Date()); //todays date
	var start = moment(startDate).format("YYYY-MM-DD HH:mm:ss"); // another date
	var duration = moment.duration(now.diff(start));
	var days = duration.asDays();
	return days.toFixed();
}
