import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";

import "./caterPackage.style.scss";
import { Button } from "@mui/material";
import WatchIcon from "@mui/icons-material/Watch";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

// components imported
import { caterPackageStatus } from "../../constants/variables";
import Table from "../../components/table/Table";
import SelectBox from "../../components/select-box/select-box.input";
import TextInput from "../../components/text-input/text-input-error.component";

import {
	addCaterPackage,
	getCaterPackageList,
	updateCaterPackage,
} from "../../redux/actions/caterPackage-action";

const caterPackageSchema = yup.object().shape({
	package_type: yup.string().required("package type is required"),
	package_duration: yup
		.number()
		.typeError("package duration number is required")
		.required("package duration number is required"),
	package_amount: yup
		.number()
		.typeError("package amount number is required")
		.required("package amount number is required"),
});

export default function CaterPackage() {
	const dispatch = useDispatch();
	const [actionButton, setActionButtonState] = useState("add");
	const caterPackages = useSelector(
		(state) => state.caterPackage.caterPackageList
	);

	// search form
	const { control, handleSubmit, reset } = useForm();

	// package form control
	const {
		control: caterPackageControl,
		handleSubmit: handleCaterPackgeSubmit,
		formState: { errors: caterPackageErrors },
		reset: caterPackageReset,
		// register: venueRegister,
	} = useForm({
		defaultValues: {},
		mode: "onBlur",
		resolver: yupResolver(caterPackageSchema),
	});

	useEffect(() => {
		dispatch(getCaterPackageList());
	}, [dispatch]);

	// handling form
	const _caterPackageFormSubmit = (data) => {
		if (actionButton === "add") {
			dispatch(addCaterPackage(data));
		} else {
			dispatch(updateCaterPackage(data));
			setActionButtonState("add");
		}

		caterPackageReset({
			package_type: "",
			package_duration: "",
			package_amount: "",
		});
	};

	const _editCaterPackage = (rowData) => {
		caterPackageReset(rowData);
		setActionButtonState("edit");
	};

	// displaying package listed
	const packageListTable = [
		{
			title: (
				<>
					<CardMembershipIcon className="menu-icon" /> Package Type
				</>
			),
			render: (rowData) => {
				return <span>{rowData.package_type}</span>;
			},
		},
		{
			title: (
				<>
					<WatchIcon className="menu-icon" /> Package Duration
				</>
			),
			render: (rowData) => {
				return <span>{rowData.package_duration} Month/s</span>;
			},
		},
		{
			title: (
				<>
					<PriceCheckIcon className="menu-icon" /> Package Amount
				</>
			),
			render: (rowData) => {
				return <span>Rs.{rowData.package_amount || 0}/-</span>;
			},
		},
		{
			title: (
				<>
					<MilitaryTechIcon className="menu-icon" /> Package Status
				</>
			),
			render: (rowData) => {
				return <span>{rowData.package_status}</span>;
			},
		},

		{
			title: (
				<>
					<SettingsSuggestIcon className="menu-icon" /> Action
				</>
			),
			render: (rowData) => {
				return (
					<div className="action-buttons-container">
						<button
							className="btn btn-outline-warning"
							onClick={() => {
								_editCaterPackage(rowData);
							}}
						>
							Edit
						</button>

						<button
							className={`btn ${
								rowData.package_status === "active"
									? "btn-outline-danger"
									: "btn-outline-success"
							}`}
							onClick={() => {
								dispatch(
									updateCaterPackage({
										...rowData,
										package_status:
											rowData.package_status === "active"
												? "inactive"
												: "active",
									})
								);
							}}
						>
							{rowData.package_status === "active"
								? "Deactivate"
								: "Activate"}
						</button>
					</div>
				);
			},
		},
	];

	// displaying form
	const caterPackageForm = () => {
		return (
			<form
				key={2}
				style={{ flex: 1 }}
				onSubmit={handleCaterPackgeSubmit(_caterPackageFormSubmit)}
			>
				<div className="package-form-input-container">
					<Controller
						name="package_type"
						control={caterPackageControl}
						defaultValue=""
						render={({ field }) => (
							<TextInput
								required
								id="outlined-size-small packageType"
								label="Package Type"
								type="text"
								field={field}
								errormessage={
									caterPackageErrors?.package_type?.message
								}
							/>
						)}
					/>
					<Controller
						name="package_duration"
						control={caterPackageControl}
						defaultValue=""
						render={({ field }) => (
							<TextInput
								required
								id="outlined-size-small packageDuration"
								label="Package Duration"
								type="number"
								field={field}
								errormessage={
									caterPackageErrors?.package_duration
										?.message
								}
							/>
						)}
					/>
					<Controller
						name="package_amount"
						control={caterPackageControl}
						defaultValue=""
						render={({ field }) => (
							<TextInput
								required
								id="outlined-size-small packageAmount"
								label="Package Amount"
								type="number"
								field={field}
								errormessage={
									caterPackageErrors?.package_amount?.message
								}
							/>
						)}
					/>
					<div className="package-action-btn">
						<button
							className="btn btn-outline-secondary text-uppercase"
							type="submit"
						>
							{actionButton === "add"
								? "Add New Package"
								: "Update Package"}
						</button>
					</div>
				</div>
			</form>
		);
	};

	// filter form
	const _clearSearchPackage = async () => {
		reset();
		await dispatch(getCaterPackageList());
	};

	const _searchPackage = async (value) => {
		const searchData = {
			package_status: value.package_status,
			package_duration: value.package_duration,
		};

		console.log(value);
		await dispatch(
			getCaterPackageList(
				searchData.package_status,
				searchData.package_duration
			)
		);
	};

	const searchPackageForm = () => {
		return (
			<div className="filter-container">
				<Controller
					name="package_status"
					control={control}
					defaultValue=""
					render={({ field }) => (
						<SelectBox
							id="outlined-size-small packageStatus"
							className="login-input search"
							label="Package Status"
							field={field}
							size="small"
							options={caterPackageStatus}
						/>
					)}
				/>
				<Controller
					name="package_duration"
					control={control}
					defaultValue=""
					render={({ field }) => (
						<TextInput
							id="outlined-size-small packageDuration"
							className="login-input search"
							type="number"
							field={field}
							size="small"
							label="Package Duration"
							placeholder="Months"
						/>
					)}
				/>

				<div className="filter-button-container">
					<Button
						variant="contained"
						onClick={handleSubmit(_clearSearchPackage)}
					>
						Clear
					</Button>
					<Button
						variant="contained"
						onClick={handleSubmit(_searchPackage)}
					>
						Search
					</Button>
				</div>
			</div>
		);
	};

	return (
		<div className="package-main-container main-content">
			<div className="cater-package-form">{caterPackageForm()}</div>
			<div className="filter-wrapper">{searchPackageForm()}</div>
			<div className="cater-package-list">
				<Table
					cols={packageListTable}
					data={caterPackages}
					isDark
					bordered
					striped
					hoverable
				/>
			</div>
		</div>
	);
}
