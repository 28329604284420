import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Table from "../../components/table/Table";
import { getAllBillingCompanies } from "../../redux/actions/company-action";

export default function BillingCompany() {
	const dispatch = useDispatch();
	const billingCompaniesData = useSelector(
		(state) => state.company.billingCompanies
	);

	useEffect(() => {
		dispatch(getAllBillingCompanies());
	}, [dispatch]);

	const tableConstants = [
		{
			title: "Company Name",
			render: (rowData) => {
				return (
					<span>
						COMP.{rowData.company_id} - {rowData.company_name} -{" "}
						{rowData.company_address}, {rowData.company_city} - [
						{rowData.company_phone} ,{rowData.company_mobile}]
					</span>
				);
			},
		},
		{
			title: "Email",
			render: (rowData) => {
				return <span>{rowData?.company_email}</span>;
			},
		},
		{
			title: "Last Active",
			render: (rowData) => {
				return (
					<span>
						{rowData.last_active !== null
							? moment(rowData?.last_active).format()
							: rowData.last_active}
					</span>
				);
			},
		},
		{
			title: "Status",
			render: (rowData) => {
				return <span>{rowData.company_status}</span>;
			},
		},
		{
			title: "Verified",
			render: (rowData) => {
				return (
					<span>{rowData.company_verified ? "True" : "False"}</span>
				);
			},
		},
		{
			title: "Created | Updated",
			render: (rowData) => {
				return <span>{rowData.createdAt?.split("T")[0]}</span>;
			},
		},
		{
			title: "Action",
			render: (rowData) => {
				return <button className="btn btn-success">ACTION</button>;
			},
		},
	];

	return (
		<div className="main-content">
			<Table
				cols={tableConstants}
				data={billingCompaniesData}
				isDark
				bordered
				striped
				hoverable
			/>
		</div>
	);
}
