let API_URL, FE_URL, NEW_API_URL;

const APP = process.env.REACT_APP_ENV;

if (APP === "production") {
	// production environment
	// API_URL = "https://api-caterme.nibjar.com/reservation";
	API_URL = "https://api-caterme.nibjar.com/reservation";
	FE_URL = "https://caterme.nibjar.com/dashboard";
	NEW_API_URL = "https://api-foovent.nibjar.com";
} else if (APP === "testing") {
	// testing environment
	API_URL = "https://test-api-caterme.nibjar.com/reservation";
	FE_URL = "https://test-caterme.nibjar.com/dashboard";
	NEW_API_URL = "https://test-api-foovent.nibjar.com";
} else if (APP === "development") {
	// development environment
	API_URL = "http://localhost:3000/reservation";
	NEW_API_URL = "http://localhost:3003";
	FE_URL = "http://localhost:3004/dashboard";
}
export { API_URL, FE_URL, NEW_API_URL };
