import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";

import "./addCompany.style.scss";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

// components imported
import {
	genderOption,
	countryOption,
	cityOption,
} from "../../constants/variables";
import SelectBox from "../../components/select-box/select-box.input";
import TextInput from "../../components/text-input/text-input-error.component";

// actions
import { signUpCompany } from "../../redux/actions/company-action";
import { getCaterPackageList } from "../../redux/actions/caterPackage-action";

const companySchema = yup.object().shape({
	company_name: yup.string().required("company name required"),
	company_venue_number: yup
		.number("Not Selected")
		.positive("must not be negative value")
		.lessThan(4, "maximum 3 venue are allowed")
		.required("venue declaration is required")
		.typeError("venue declaration is required"),
	company_email: yup
		.string()
		.required("company email is required")
		.email("Please enter a valid email"),
	company_mobile: yup
		.number()
		.required("company mobile number is required")
		.typeError("company mobile number is required"),
	company_phone: yup
		.number()
		.required("company phone number is required")
		.typeError("company phone number is required"),
	company_website: yup.string(),
	company_facebook: yup.string(),
	company_address: yup.string().required("company address is required"),
	company_city: yup
		.string()
		.matches(/^([^0-9]*)$/, "No numbers allowed")
		.required("city of company is required"),
	company_country: yup
		.string()
		.matches(/^([^0-9]*)$/, "No numbers allowed")
		.required("country of company is required"),
});

const venueSchema = yup.object().shape({
	venue_name: yup.string().required("venue name is required"),
	venue_phone: yup
		.number()
		.typeError("venue contact number is required")
		.required("venue contact number is required"),
	venue_address: yup.string().required("venue address required"),
	venue_city: yup
		.string()
		.matches(/^([^0-9]*)$/, "No numbers allowed")
		.required("city of venue is required"),
	venue_country: yup
		.string()
		.matches(/^([^0-9]*)$/, "No numbers allowed")
		.required("country of venue is required"),
});

const userSchema = yup.object().shape({
	first_name: yup
		.string()
		.matches(/^([^0-9]*)$/, "No numbers allowed")
		.required("required"),
	last_name: yup
		.string()
		.required("required")
		.matches(/^([^0-9]*)$/, "No numbers allowed"),
	user_mobile: yup
		.number()
		.typeError("Must be a number")
		.required("required"),
	user_email: yup
		.string()
		.required("required")
		.email("Please enter a valid email"),
	password: yup
		.string()
		.required("required")
		.min(8, "Min characters should be 8"),
	confirm_password: yup
		.string()
		.oneOf([yup.ref("password"), null], "Passwords must match")
		.required("required"),
	user_gender: yup.string().required("required"),
	user_dob: yup.string().required("required"),
	user_phone: yup.number().typeError("Must be a number").required("required"),
	user_address: yup.string().required("required"),
	user_city: yup
		.string()
		.matches(/^([^0-9]*)$/, "No numbers allowed")
		.required("required"),
	user_country: yup
		.string()
		.matches(/^([^0-9]*)$/, "No numbers allowed")
		.required("required"),
});

const caterSubscriptionSchema = yup.object().shape({
	package_type: yup.string().required("package type is required"),
	package_duration: yup
		.number()
		.typeError("package duration number is required")
		.required("package duration number is required"),
	subscription_amount: yup
		.number()
		.typeError("package amount number is required")
		.required("package amount number is required"),
	renewed_date: yup.string().required("required"),
	expiration_date: yup.string().required("required"),
});

export default function AddCompany() {
	const dispatch = useDispatch();
	// displaying control state
	const [companyFormState, setCompanyFormState] = useState(true);
	const [venueFormState, setVenueFormState] = useState(false);
	const [userFormState, setUserFormState] = useState(false);
	const [subscriptionFormState, setSubscriptionFormState] = useState(false);
	const [showPreview, setShowPreview] = useState(false);

	// redux
	const caterPackages = useSelector(
		(state) => state.caterPackage.caterPackageList
	);
	const caterPackageDropdown = caterPackages.map((caterPackage) => {
		return {
			id: caterPackage.package_type,
			value: caterPackage.package_type,
			package_duration: caterPackage.package_duration,
			package_amount: caterPackage.package_amount,
		};
	});

	// form submission state
	const [signUpState, setSignUpState] = useState({
		// company_country: "asdq",
		// company_city: "asdad",
		// company_address: "asdasd",
		// company_facebook: "",
		// company_website: "",
		// company_phone: 123,
		// company_mobile: 123,
		// company_email: "as@sa.com",
		// company_venue_number: 1,
		// company_name: "test",
		// venue_country: "vnvbn",
		// venue_city: "jhgjgh",
		// venue_address: "yiuit",
		// venue_phone: 1441,
		// venue_name: "qwer",
		// user_country: "sdff",
		// user_city: "sdfsf",
		// user_address: "asad 34",
		// user_phone: 123,
		// user_dob: "2023-09-06",
		// user_gender: "dsfs",
		// confirm_password: "1234567890",
		// password: "1234567890",
		// user_email: "sanat123@yopmail.com",
		// user_mobile: 78078978,
		// last_name: "waad",
		// first_name: "ssdf",
		// expiration_date: "2023-12-26",
		// renewed_date: "2023-09-25",
		// subscription_amount: 3500,
		// package_duration: 3,
		// package_type: "Three Months",
	});

	// company form control
	const {
		control: companyControl,
		handleSubmit: handleCompanySubmit,
		formState: { errors: companyErrors },
		// reset: companyReset,
		// register: companyRegister,
		// watch: companyWatch,
	} = useForm({
		defaultValues: {},
		mode: "onBlur",
		resolver: yupResolver(companySchema),
	});

	// venue form control
	const {
		control: venueControl,
		handleSubmit: handleVenueSubmit,
		formState: { errors: venueErrors },
		// reset: venueReset,
		// register: venueRegister,
	} = useForm({
		defaultValues: {},
		mode: "onBlur",
		resolver: yupResolver(venueSchema),
	});

	// user form control
	const {
		control: userControl,
		handleSubmit: handleUserSubmit,
		formState: { errors: userErrors },
		// reset: userReset,
		// register: userRegister,
	} = useForm({
		defaultValues: {},
		mode: "onBlur",
		resolver: yupResolver(userSchema),
	});

	// subscription form control
	const {
		control: caterSubscriptionControl,
		handleSubmit: handleCaterSubscriptionSubmit,
		formState: { errors: caterSubscriptionErrors },
		watch: subscriptionTypeWatch,
		setValue: setSubscriptionValue,
	} = useForm({
		defaultValues: {},
		mode: "onBlur",
		resolver: yupResolver(caterSubscriptionSchema),
	});

	useEffect(() => {
		dispatch(getCaterPackageList());
	}, [dispatch]);

	// handling view
	const _companyTab = () => {
		setCompanyFormState(true);
		setVenueFormState(false);
		setUserFormState(false);
		setSubscriptionFormState(false);
		setShowPreview(false);
	};
	const _venueTab = () => {
		setCompanyFormState(false);
		setVenueFormState(true);
		setUserFormState(false);
		setSubscriptionFormState(false);
		setShowPreview(false);
	};
	const _userTab = () => {
		setCompanyFormState(false);
		setVenueFormState(false);
		setUserFormState(true);
		setSubscriptionFormState(false);
		setShowPreview(false);
	};
	const _caterSubscriptionTab = () => {
		setCompanyFormState(false);
		setVenueFormState(false);
		setUserFormState(false);
		setSubscriptionFormState(true);
		setShowPreview(false);
	};
	const _previewTab = () => {
		setCompanyFormState(false);
		setVenueFormState(false);
		setUserFormState(false);
		setSubscriptionFormState(false);
		setShowPreview(true);
	};

	// handling form
	const _companyFormSubmit = (data) => {
		setSignUpState({ ...signUpState, ...data });
		setCompanyFormState(false);
		setVenueFormState(true);
	};

	const _venueFormSubmit = (data) => {
		setSignUpState({ ...signUpState, ...data });
		setVenueFormState(false);
		setUserFormState(true);
	};

	const _userFormSubmit = (data) => {
		setSignUpState({ ...signUpState, ...data });
		setUserFormState(false);
		setSubscriptionFormState(true);
	};

	const subscriptionWatchFields = subscriptionTypeWatch("package_type");
	const _packageChoose = () => {
		if (subscriptionWatchFields) {
			const packageValue = caterPackageDropdown.find(
				(caterPackage) => caterPackage.id === subscriptionWatchFields
			);
			setSubscriptionValue(
				"subscription_amount",
				packageValue.package_amount
			);
			setSubscriptionValue(
				"package_duration",
				packageValue.package_duration
			);
		}
	};
	const _caterSubscriptionFormSubmit = (data) => {
		setSignUpState({ ...signUpState, ...data });
		setSubscriptionFormState(false);
		setShowPreview(true);
	};

	const _signUp = () => {
		dispatch(signUpCompany(signUpState));
	};

	// displaying form
	const companyForm = () => {
		return (
			<form
				key={1}
				style={{ flex: 1 }}
				onSubmit={handleCompanySubmit(_companyFormSubmit)}
			>
				<div className="form-main-content bg-default">
					<div className="form-group">
						<div className="form-input-container">
							<Controller
								name="company_name"
								control={companyControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small companyName"
										label="Company Name"
										type="text"
										field={field}
										errormessage={
											companyErrors?.company_name?.message
										}
									/>
								)}
							/>
							<Controller
								name="company_venue_number"
								control={companyControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small venueNumber"
										label="No. of Venue"
										type="number"
										field={field}
										errormessage={
											companyErrors?.company_venue_number
												?.message
										}
									/>
								)}
							/>
							<Controller
								name="company_email"
								control={companyControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small companyEmail"
										label="Company Email"
										type="email"
										field={field}
										errormessage={
											companyErrors?.company_email
												?.message
										}
									/>
								)}
							/>
							<Controller
								name="company_mobile"
								control={companyControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small companyMobile"
										label="Company Mobile"
										type="number"
										field={field}
										errormessage={
											companyErrors?.company_mobile
												?.message
										}
									/>
								)}
							/>
							<Controller
								name="company_phone"
								control={companyControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small companyPhone"
										label="Company Phone"
										type="number"
										field={field}
										errormessage={
											companyErrors?.company_phone
												?.message
										}
									/>
								)}
							/>
						</div>

						<div className="form-input-container">
							<Controller
								name="company_website"
								control={companyControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										id="outlined-size-small companyWebsite"
										label="Company Website"
										type="text"
										field={field}
										errormessage={
											companyErrors?.company_website
												?.message
										}
									/>
								)}
							/>
							<Controller
								name="company_facebook"
								control={companyControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										id="outlined-size-small companyFacebook"
										label="Company Facebook"
										type="text"
										field={field}
										errormessage={
											companyErrors?.company_facebook
												?.message
										}
									/>
								)}
							/>
							<Controller
								name="company_address"
								control={companyControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small companyAddress"
										label="Company Address"
										type="text"
										field={field}
										errormessage={
											companyErrors?.company_address
												?.message
										}
									/>
								)}
							/>
							<Controller
								name="company_city"
								control={companyControl}
								defaultValue=""
								render={({ field }) => (
									<SelectBox
										id="outlined-size-small companyCity"
										className="search"
										label="City"
										field={field}
										size="small"
										options={cityOption}
										required
										errormessage={
											companyErrors?.company_city?.message
										}
									/>
								)}
							/>
							<Controller
								name="company_country"
								control={companyControl}
								defaultValue=""
								render={({ field }) => (
									<SelectBox
										id="outlined-size-small companyCountry"
										className="search"
										label="Country"
										field={field}
										size="small"
										options={countryOption}
										required
										errormessage={
											companyErrors?.company_country
												?.message
										}
									/>
								)}
							/>
						</div>
					</div>

					<div className="company-action-btn">
						<button
							className="btn btn-outline-secondary"
							type="submit"
						>
							Next {">"}
						</button>
					</div>
				</div>
			</form>
		);
	};

	const venueForm = () => {
		return (
			<form
				key={2}
				style={{ flex: 1 }}
				onSubmit={handleVenueSubmit(_venueFormSubmit)}
			>
				<div className="form-main-content bg-default">
					<div className="form-group">
						<div className="form-input-container">
							<Controller
								name="venue_name"
								control={venueControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small venueName"
										label="Venue Name"
										type="text"
										field={field}
										errormessage={
											venueErrors?.venue_name?.message
										}
									/>
								)}
							/>

							<Controller
								name="venue_phone"
								control={venueControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small venuePhone"
										label="Venue Phone"
										type="number"
										field={field}
										errormessage={
											venueErrors?.venue_phone?.message
										}
									/>
								)}
							/>

							<Controller
								name="venue_address"
								control={venueControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small venueAddress"
										label="Venue Address"
										type="text"
										field={field}
										errormessage={
											venueErrors?.venue_address?.message
										}
									/>
								)}
							/>

							<Controller
								name="venue_city"
								control={venueControl}
								defaultValue=""
								render={({ field }) => (
									<SelectBox
										id="outlined-size-small venueCity"
										className="search"
										label="City"
										field={field}
										size="small"
										options={cityOption}
										required
										errormessage={
											venueErrors?.venue_city?.message
										}
									/>
								)}
							/>
							<Controller
								name="venue_country"
								control={venueControl}
								defaultValue=""
								render={({ field }) => (
									<SelectBox
										id="outlined-size-small venueCountry"
										className="search"
										label="Country"
										field={field}
										size="small"
										options={countryOption}
										required
										errormessage={
											venueErrors?.venue_country?.message
										}
									/>
								)}
							/>
						</div>
					</div>

					<div className="user-action-btn">
						<button
							className="btn btn-outline-secondary"
							type="button"
							onClick={() => {
								setCompanyFormState(true);
								setVenueFormState(false);
							}}
						>
							{"< "}Back
						</button>
						<button
							className="btn btn-outline-secondary"
							type="submit"
						>
							Next {">"}
						</button>
					</div>
				</div>
			</form>
		);
	};

	const userForm = () => {
		return (
			<form
				key={3}
				style={{ flex: 1 }}
				onSubmit={handleUserSubmit(_userFormSubmit)}
			>
				<div className="form-main-content bg-default">
					<div className="form-group">
						<div className="form-input-container">
							<Controller
								name="first_name"
								control={userControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small firstName"
										label="First Name"
										type="text"
										field={field}
										errormessage={
											userErrors?.first_name?.message
										}
									/>
								)}
							/>
							<Controller
								name="last_name"
								control={userControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small lastName"
										label="Last Name"
										type="text"
										field={field}
										errormessage={
											userErrors?.last_name?.message
										}
									/>
								)}
							/>
							<Controller
								name="user_mobile"
								control={userControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small userMobile"
										label="Mobile Number"
										type="number"
										field={field}
										errormessage={
											userErrors?.user_mobile?.message
										}
									/>
								)}
							/>
							<Controller
								name="user_email"
								control={userControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small userEmail"
										label="User Email"
										type="email"
										field={field}
										errormessage={
											userErrors?.user_email?.message
										}
									/>
								)}
							/>
							<Controller
								name="password"
								control={userControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small userPassword"
										label="Password"
										type="password"
										field={field}
										errormessage={
											userErrors?.password?.message
										}
									/>
								)}
							/>
							<Controller
								name="confirm_password"
								control={userControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small confirmPassword"
										label="Confirm Password"
										type="password"
										field={field}
										errormessage={
											userErrors?.confirm_password
												?.message
										}
									/>
								)}
							/>
						</div>
						<div className="form-input-container">
							<Controller
								name="user_gender"
								control={userControl}
								defaultValue=""
								render={({ field }) => (
									<SelectBox
										id="outlined-size-small package_type"
										className="search"
										label="Gender"
										field={field}
										size="small"
										options={genderOption}
										required
										errormessage={
											userErrors?.user_gender?.message
												?.user_gender?.message
										}
									/>
								)}
							/>
							<Controller
								name="user_dob"
								control={userControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small userDOB"
										// label="Date of Birth"
										type="date"
										field={field}
										errormessage={
											userErrors?.user_dob?.message
										}
									/>
								)}
							/>
							<Controller
								name="user_phone"
								control={userControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small userPhone"
										label="Phone Number"
										type="number"
										field={field}
										errormessage={
											userErrors?.user_phone?.message
										}
									/>
								)}
							/>
							<Controller
								name="user_address"
								control={userControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small userAddress"
										label="Address"
										type="text"
										field={field}
										errormessage={
											userErrors?.user_address?.message
										}
									/>
								)}
							/>
							<Controller
								name="user_city"
								control={userControl}
								defaultValue=""
								render={({ field }) => (
									<SelectBox
										id="outlined-size-small userCity"
										className="search"
										label="City"
										field={field}
										size="small"
										options={cityOption}
										required
										errormessage={
											userErrors?.user_city?.message
										}
									/>
								)}
							/>
							<Controller
								name="user_country"
								control={userControl}
								defaultValue=""
								render={({ field }) => (
									<SelectBox
										id="outlined-size-small userCountry"
										className="search"
										label="Country"
										field={field}
										size="small"
										options={countryOption}
										required
										errormessage={
											userErrors?.user_country?.message
										}
									/>
								)}
							/>
						</div>
					</div>

					<div className="user-action-btn">
						<button
							className="btn btn-outline-secondary"
							type="button"
							onClick={() => {
								setVenueFormState(true);
								setUserFormState(false);
							}}
						>
							{"< "}Back
						</button>
						<button
							className="btn btn-outline-secondary"
							type="submit"
						>
							Next {">"}
						</button>
					</div>
				</div>
			</form>
		);
	};

	const caterSubscriptionForm = () => {
		return (
			<form
				key={2}
				style={{ flex: 1 }}
				onSubmit={handleCaterSubscriptionSubmit(
					_caterSubscriptionFormSubmit
				)}
			>
				<div className="form-main-content bg-default">
					<div className="form-group">
						<div className="form-input-container">
							<Controller
								name="package_type"
								control={caterSubscriptionControl}
								defaultValue=""
								render={({ field }) => (
									<SelectBox
										id="outlined-size-small package_type"
										className="search"
										label="Package Type"
										field={field}
										size="small"
										options={caterPackageDropdown}
										required
										errormessage={
											caterSubscriptionErrors
												?.package_type?.message
										}
										onChange={_packageChoose()}
									/>
								)}
							/>
							<Controller
								name="package_duration"
								control={caterSubscriptionControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small packageDuration"
										label="Package Duration"
										type="number"
										field={field}
										errormessage={
											caterSubscriptionErrors
												?.package_duration?.message
										}
									/>
								)}
							/>
							<Controller
								name="subscription_amount"
								control={caterSubscriptionControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small subscriptionAmount"
										label="Subscription Amount"
										type="number"
										field={field}
										errormessage={
											caterSubscriptionErrors
												?.subscription_amount?.message
										}
									/>
								)}
							/>
							<Controller
								name="renewed_date"
								control={caterSubscriptionControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small renewedDate"
										label="Renewed Date"
										type="date"
										field={field}
										errormessage={
											caterSubscriptionErrors
												?.renewed_date?.message
										}
									/>
								)}
							/>
							<Controller
								name="expiration_date"
								control={caterSubscriptionControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small expirationDate"
										label="Expiration Date"
										type="date"
										field={field}
										errormessage={
											caterSubscriptionErrors
												?.expiration_date?.message
										}
									/>
								)}
							/>
						</div>
					</div>
					<div className="user-action-btn">
						<button
							className="btn btn-outline-secondary"
							type="button"
							onClick={() => {
								setUserFormState(true);
								setSubscriptionFormState(false);
							}}
						>
							{"< "}Back
						</button>
						<button
							className="btn btn-outline-secondary"
							type="submit"
						>
							Preview {">"}
						</button>
					</div>
				</div>
			</form>
		);
	};

	// displaying data
	const companyInfo = useMemo(() => {
		return [
			{
				label: "Company Name",
				value: signUpState.company_name,
			},
			{
				label: "No. of Venue",
				value: signUpState.company_venue_number + " venue/s",
			},
			{
				label: "Email",
				value: signUpState.company_email,
			},
			{
				label: "Mobile",
				value: signUpState.company_mobile,
			},
			{
				label: "Phone",
				value: signUpState.company_phone,
			},
			{
				label: "Website",
				value: signUpState.company_website || "-",
			},
			{
				label: "Facebook",
				value: signUpState.company_facebook || "-",
			},
			{
				label: "Address",
				value: signUpState.company_address,
			},
			{
				label: "City",
				value: signUpState.company_city,
			},
			{
				label: "Country",
				value: signUpState.company_country,
			},
		];
	}, [signUpState]);

	const venueInfo = useMemo(() => {
		return [
			{
				label: "Venue Name",
				value: signUpState.venue_name,
			},
			{
				label: "Phone",
				value: signUpState.venue_phone,
			},
			{
				label: "Address",
				value: signUpState.venue_address,
			},
			{
				label: "City",
				value: signUpState.venue_city,
			},
			{
				label: "Country",
				value: signUpState.venue_country,
			},
		];
	}, [signUpState]);

	const userInfo = useMemo(() => {
		return [
			{
				label: "First Name",
				value: signUpState.first_name,
			},
			{
				label: "Last Name",
				value: signUpState.last_name,
			},
			{
				label: "Gender",
				value: signUpState.user_gender,
			},
			{
				label: "Date of Birth",
				value: signUpState.user_dob || "-",
			},
			{
				label: "Mobile",
				value: signUpState.user_mobile,
			},
			{
				label: "Email",
				value: signUpState.user_email,
			},
			{
				label: "Phone",
				value: signUpState.user_phone || "-",
			},
			{
				label: "Address",
				value: signUpState.user_address,
			},
			{
				label: "City",
				value: signUpState.user_city,
			},
			{
				label: "Country",
				value: signUpState.user_country,
			},
		];
	}, [signUpState]);

	const caterSubscriptionInfo = useMemo(() => {
		return [
			{
				label: "Package Type",
				value: signUpState.package_type,
			},
			{
				label: "Package Duration",
				value: (signUpState.package_duration || "-") + " Month/s",
			},
			{
				label: "Renewed Date",
				value: signUpState.renewed_date,
			},
			{
				label: "Expiration Date",
				value: signUpState.expiration_date,
			},
			{
				label: "Subscription Amount",
				value: "Rs." + (signUpState.subscription_amount || "-"),
			},
		];
	}, [signUpState]);

	return (
		<>
			{
				<div className="signup-main">
					<div className="signup-phase">
						<div
							className="company-form"
							onClick={() => {
								_companyTab();
							}}
						>
							Company Information
						</div>
						<div
							className="venue-form"
							onClick={() => {
								_venueTab();
							}}
						>
							Venue Information
						</div>
						<div
							className="user-form"
							onClick={() => {
								_userTab();
							}}
						>
							User Information
						</div>
						<div
							className="user-form"
							onClick={() => {
								_caterSubscriptionTab();
							}}
						>
							Subscription
						</div>
						<div
							className="preview-form"
							onClick={() => {
								_previewTab();
							}}
						>
							Preview
						</div>
					</div>

					{companyFormState && companyForm()}
					{venueFormState && venueForm()}
					{userFormState && userForm()}
					{subscriptionFormState && caterSubscriptionForm()}

					{showPreview && (
						<div className="preview-data-main">
							<div className="preview-company">
								<div className="section-header">
									<h2>Company Information</h2>
									<div
										className="form-edit-button"
										onClick={() => _companyTab()}
									>
										Edit
									</div>
								</div>
								<div className="section-body">
									{companyInfo.map((info, index) => (
										<div key={index} className="form-data">
											<div className="data-label">
												{info.label}
											</div>
											<div className="data-value">
												{info.value}
											</div>
										</div>
									))}
								</div>
							</div>

							<div className="preview-venue">
								<div className="section-header">
									<h2>Venue Information</h2>
									<div
										className="form-edit-button"
										onClick={() => _venueTab()}
									>
										Edit
									</div>
								</div>
								<div className="section-body">
									{venueInfo.map((info, index) => (
										<div key={index} className="form-data">
											<div className="data-label">
												{info.label}
											</div>
											<div className="data-value">
												{info.value}
											</div>
										</div>
									))}
								</div>
							</div>

							<div className="preview-user">
								<div className="section-header">
									<h2>User Information</h2>
									<div
										className="form-edit-button"
										onClick={() => _userTab()}
									>
										Edit
									</div>
								</div>
								<div className="section-body">
									{userInfo.map((info, index) => (
										<div key={index} className="form-data">
											<div className="data-label">
												{info.label}
											</div>
											<div className="data-value">
												{info.value}
											</div>
										</div>
									))}
								</div>
							</div>

							<div className="preview-user">
								<div className="section-header">
									<h2>Subscription Information</h2>
									<div
										className="form-edit-button"
										onClick={() => _caterSubscriptionTab()}
									>
										Edit
									</div>
								</div>
								<div className="section-body">
									{caterSubscriptionInfo.map(
										(info, index) => (
											<div
												key={index}
												className="form-data"
											>
												<div className="data-label">
													{info.label}
												</div>
												<div className="data-value">
													{info.value}
												</div>
											</div>
										)
									)}
								</div>
							</div>

							<div>
								<button
									className="btn btn-outline-secondary text-uppercase"
									onClick={() => _signUp()}
								>
									Sign Up Company
								</button>
							</div>
						</div>
					)}
				</div>
			}
		</>
	);
}
