export const registrationDocument = [
	{ id: "pan", value: "PAV" },
	{ id: "vat", value: "VAT" },
];

export const genderOption = [
	{ id: "male", value: "Male" },
	{ id: "female", value: "Female" },
	{ id: "other", value: "Other" },
];

export const caterPackageStatus = [
	{ id: "active", value: "Active" },
	{ id: "inactive", value: "Inactive" },
];

export const subscriptionStatusOption = [
	{ id: "active", value: "Active" },
	{ id: "expired", value: "Expired" },
	{ id: "active_due", value: "Payment Due Active" },
];

export const issuePage = [
	{ id: "booking", value: "Booking" },
	{ id: "dashboard", value: "Dashboard" },
	{ id: "settings", value: "Settings" },
];

export const supportType = [
	{ id: "crash", value: "Crash" },
	{ id: "error", value: "Error" },
	{ id: "feature request", value: "Feature Request" },
	{ id: "help", value: "Help" },
];
export const supportStatus = [
	{ id: "pending", value: "Pending" },
	{ id: "open", value: "Open" },
	{ id: "wip", value: "In Progress" },
	{ id: "closed", value: "Closed" },
];

export const countryOption = [
	{
		id: "nepal",
		value: "Nepal",
	},
];

export const provinceOption = [
	{
		id: "Province No. 1",
		value: "Province No. 1",
	},
	{
		id: "Province No. 2",
		value: "Province No. 2",
	},
	{
		id: "Bagmati Province",
		value: "Bagmati Province",
	},
	{
		id: "Gandaki Province",
		value: "Gandaki Province",
	},
	{ id: "Lumbini Province", value: "Lumbini Province" },
	{ id: "Karnali Province", value: "Karnali Province" },
	{ id: "Sudurpashchim Province", value: "Sudurpashchim Province" },
];

export const districtOption = [
	{
		id: "Achham",
		value: "Achham",
	},
	{
		id: "Arghakhanchi",
		value: "Arghakhanchi",
	},
	{
		id: "Baglung",
		value: "Baglung",
	},
	{
		id: "Baitadi",
		value: "Baitadi",
	},
	{
		id: "Bajhang",
		value: "Bajhang",
	},
	{
		id: "Bajura",
		value: "Bajura",
	},
	{
		id: "Banke",
		value: "Banke",
	},
	{
		id: "Bara",
		value: "Bara",
	},
	{
		id: "Bardiya",
		value: "Bardiya",
	},
	{
		id: "Bhaktapur",
		value: "Bhaktapur",
	},
	{
		id: "Bhojpur",
		value: "Bhojpur",
	},
	{
		id: "Chitwan",
		value: "Chitwan",
	},
	{
		id: "Dadeldhura",
		value: "Dadeldhura",
	},
	{
		id: "Dailekh",
		value: "Dailekh",
	},
	{
		id: "Dang",
		value: "Dang",
	},
	{
		id: "Darchula",
		value: "Darchula",
	},
	{
		id: "Dhading",
		value: "Dhading",
	},
	{
		id: "Dhankuta",
		value: "Dhankuta",
	},
	{
		id: "Dhanusha",
		value: "Dhanusha",
	},
	{
		id: "Dolakha",
		value: "Dolakha",
	},
	{
		id: "Dolpa",
		value: "Dolpa",
	},
	{
		id: "Doti",
		value: "Doti",
	},
	{
		id: "Eastern Rukum",
		value: "Eastern Rukum",
	},
	{
		id: "Gorkha",
		value: "Gorkha",
	},
	{
		id: "Gulmi",
		value: "Gulmi",
	},
	{
		id: "Humla",
		value: "Humla",
	},
	{
		id: "Ilam",
		value: "Ilam",
	},
	{
		id: "Jajarkot",
		value: "Jajarkot",
	},
	{
		id: "Jhapa",
		value: "Jhapa",
	},
	{
		id: "Jumla",
		value: "Jumla",
	},
	{
		id: "Kailali",
		value: "Kailali",
	},
	{
		id: "Kalikot",
		value: "Kalikot",
	},
	{
		id: "Kanchanpur",
		value: "Kanchanpur",
	},
	{
		id: "Kapilvastu",
		value: "Kapilvastu",
	},
	{
		id: "Kaski",
		value: "Kaski",
	},
	{
		id: "Kathmandu",
		value: "Kathmandu",
	},
	{
		id: "Kavrepalanchok",
		value: "Kavrepalanchok",
	},
	{
		id: "Khotang",
		value: "Khotang",
	},
	{
		id: "Lalitpur",
		value: "Lalitpur",
	},
	{
		id: "Lamjung",
		value: "Lamjung",
	},
	{
		id: "Mahottari",
		value: "Mahottari",
	},
	{
		id: "Makwanpur",
		value: "Makwanpur",
	},
	{
		id: "Manang",
		value: "Manang",
	},
	{
		id: "Morang",
		value: "Morang",
	},
	{
		id: "Mugu",
		value: "Mugu",
	},
	{
		id: "Mustang",
		value: "Mustang",
	},
	{
		id: "Myagdi",
		value: "Myagdi",
	},
	{
		id: "Nawalparasi East",
		value: "Nawalparasi East",
	},
	{
		id: "Nawalparasi West",
		value: "Nawalparasi West",
	},
	{
		id: "Nuwakot",
		value: "Nuwakot",
	},
	{
		id: "Okhaldhunga",
		value: "Okhaldhunga",
	},
	{
		id: "Palpa",
		value: "Palpa",
	},
	{
		id: "Panchthar",
		value: "Panchthar",
	},
	{
		id: "Parbat",
		value: "Parbat",
	},
	{
		id: "Parsa",
		value: "Parsa",
	},
	{
		id: "Pyuthan",
		value: "Pyuthan",
	},
	{
		id: "Ramechhap",
		value: "Ramechhap",
	},
	{
		id: "Rasuwa",
		value: "Rasuwa",
	},
	{
		id: "Rautahat",
		value: "Rautahat",
	},
	{
		id: "Rolpa",
		value: "Rolpa",
	},
	{
		id: "Rukum East",
		value: "Rukum East",
	},
	{
		id: "Rukum West",
		value: "Rukum West",
	},
	{
		id: "Rupandehi",
		value: "Rupandehi",
	},
	{
		id: "Salyan",
		value: "Salyan",
	},
	{
		id: "Sankhuwasabha",
		value: "Sankhuwasabha",
	},
	{
		id: "Saptari",
		value: "Saptari",
	},
	{
		id: "Sarlahi",
		value: "Sarlahi",
	},
	{
		id: "Sindhuli",
		value: "Sindhuli",
	},
	{
		id: "Sindhupalchok",
		value: "Sindhupalchok",
	},
	{
		id: "Siraha",
		value: "Siraha",
	},
	{
		id: "Solukhumbu",
		value: "Solukhumbu",
	},
	{
		id: "Sunsari",
		value: "Sunsari",
	},
	{
		id: "Surkhet",
		value: "Surkhet",
	},
	{
		id: "Syangja",
		value: "Syangja",
	},
	{
		id: "Tanahun",
		value: "Tanahun",
	},
	{
		id: "Taplejung",
		value: "Taplejung",
	},
	{
		id: "Terhathum",
		value: "Terhathum",
	},
	{
		id: "Udayapur",
		value: "Udayapur",
	},
	{
		id: "Western Rukum",
		value: "Western Rukum",
	},
];

export const cityOption = [
	{ id: "kathmandu", value: "Kathmandu" },
	{ id: "pokhara", value: "Pokhara" },
	{ id: "lalitpur", value: "Lalitpur" },
	{ id: "biratnagar", value: "Biratnagar" },
	{ id: "birgunj", value: "Birgunj" },
	{ id: "bharatpur", value: "Bharatpur" },
	{ id: "janakpur", value: "Janakpur" },
	{ id: "hetauda", value: "Hetauda" },
	{ id: "dharan", value: "Dharan" },
	{ id: "butwal", value: "Butwal" },
	{ id: "bhaktapur", value: "Bhaktapur" },
	{ id: "birendranagar", value: "Birendranagar" },
	{ id: "itahari", value: "Itahari" },
	{ id: "nepalganj", value: "Nepalganj" },
	{ id: "ghorahi", value: "Ghorahi" },
	{ id: "siddharthanagar", value: "Siddharthanagar" },
	{ id: "rajbiraj", value: "Rajbiraj" },
	{ id: "dhangadhi", value: "Dhangadhi" },
	{ id: "mahendranagar", value: "Mahendranagar" },
	{ id: "gulariya", value: "Gulariya" },
	{ id: "tulsipur", value: "Tulsipur" },
	{ id: "narayangarh", value: "Narayangarh" },
	{ id: "ithari", value: "Ithari" },
	{ id: "baglung", value: "Baglung" },
	{ id: "banepa", value: "Banepa" },
];
