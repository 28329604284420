import { Button } from "@mui/material";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import SelectBox from "../../components/select-box/select-box.input";
import TextInput from "../../components/text-input/text-input-error.component";
import {
	issuePage,
	supportStatus,
	supportType,
} from "../../constants/variables";
import { getDateDuration } from "../../helpers/functions";
import {
	getSupportList,
	searchSupportList,
	updateSupportIssue,
} from "../../redux/actions/support-action";

import { FE_URL } from "../../constants/url";
import { Link } from "react-router-dom";
// import moment from "moment";

function SupportIssuesPage() {
	const dispatch = useDispatch();
	const { supportList } = useSelector((state) => state.support);
	const { control, handleSubmit, reset } = useForm();

	useEffect(() => {
		dispatch(getSupportList());
	}, [dispatch]);

	const _searchSupport = async (value) => {
		const searchData = {
			support_page: value.support_page,
			support_type: value.support_type,
			support_status: value.support_status,
			createdAt: value.date_to,
		};

		await dispatch(searchSupportList(searchData));
	};

	const _clearSearchSupport = async () => {
		reset();
		await dispatch(searchSupportList({}));
	};

	const _updateSupport = async (supportData) => {
		await dispatch(
			updateSupportIssue({
				...supportData,
				support_status: "closed",
				grant_access: false,
			})
		);
	};

	return (
		<div className="main-content">
			<div className="filter-wrapper">
				<div className="filter-container">
					<Controller
						name="support_page"
						control={control}
						defaultValue=""
						render={({ field }) => (
							<SelectBox
								// id="outlined-size-small email"
								className="search"
								label="Issue Page"
								field={field}
								size="small"
								options={issuePage}
							/>
						)}
					/>
					<Controller
						name="support_type"
						control={control}
						defaultValue=""
						render={({ field }) => (
							<SelectBox
								// id="outlined-size-small email"
								className="search"
								label="Support Type"
								field={field}
								size="small"
								options={supportType}
							/>
						)}
					/>
					<Controller
						name="support_status"
						control={control}
						defaultValue=""
						render={({ field }) => (
							<SelectBox
								// id="outlined-size-small email"
								className="search"
								label="Support Status"
								field={field}
								size="small"
								options={supportStatus}
							/>
						)}
					/>

					<Controller
						name="date_to"
						control={control}
						defaultValue=""
						render={({ field }) => (
							<TextInput
								id="outlined-size-small email"
								className="login-input search"
								type="date"
								field={field}
								size="small"
								placeholder=""
							/>
						)}
					/>
				</div>
				<div className="filter-button-container">
					<Button
						variant="contained"
						onClick={handleSubmit(_clearSearchSupport)}
					>
						Clear
					</Button>
					<Button
						variant="contained"
						onClick={handleSubmit(_searchSupport)}
					>
						Search
					</Button>
				</div>
			</div>
			<div className="table-responsive">
				<table className="table table-responsive table-bordered table-hover table-striped text-capitalize">
					<thead>
						<tr>
							<th>#</th>
							<th>Issued</th>
							<th>User</th>
							<th>Page</th>
							<th>Support Type</th>
							<th>Issue While</th>
							<th>Status</th>
							<th>Access</th>
							<th>Close</th>
						</tr>
					</thead>
					<tbody>
						{supportList?.map((support, index) => (
							<tr key={index}>
								<td>{index + 1}</td>
								<td>
									{/* {moment(support.createdAt).format(
										"YYYY-MM-DD HH:mm:ss"
									)}{" "} */}
									{getDateDuration(support.createdAt)} day/s
								</td>
								<td>
									{support?.ticket_by} -{" "}
									{support?.company_info.company_name} -{" "}
									{support.user_mobile} -{" "}
									{support?.company_info.company_phone} -{" "}
									{support.support_call ? "Call" : "No Call"}
								</td>
								<td>{support.support_page}</td>
								<td>{support.support_type}</td>
								<td>{support.support_while}</td>
								<td>{support.support_status}</td>
								<td>
									{support.grant_access ? (
										<Link
											to={{
												pathname: `${FE_URL}?user=${support.user_info_id}&company=${support.company_info_id}`,
											}}
											target="_blank"
										>
											<button className="btn btn-outline-info">
												View
											</button>
										</Link>
									) : (
										"-"
									)}
								</td>
								<td>
									<button
										className="btn btn-outline-secondary"
										onClick={() => _updateSupport(support)}
									>
										Update
									</button>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
}

export default SupportIssuesPage;
