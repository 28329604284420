import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Table from "../../components/table/Table";
import {
	getAllEvaMembers,
	signUpCompany,
} from "../../redux/actions/company-action";

export default function EvaMember() {
	const dispatch = useDispatch();
	const evaNepalMembers = useSelector(
		(state) => state.company.evaNepalMembers
	);

	useEffect(() => {
		dispatch(getAllEvaMembers());
	}, [dispatch]);

	const tableConstants = [
		{
			title: " ID",
			render: (rowData) => {
				return <span>{rowData.company_id}</span>;
			},
		},
		{
			title: "Company Name",
			render: (rowData) => {
				return (
					<span>
						{rowData.company_name} - {rowData.company_address},{" "}
						{rowData.company_city} - [{rowData.company_phone} ,
						{rowData.company_mobile}]
					</span>
				);
			},
		},
		{
			title: "Socials",
			render: (rowData) => {
				return <span>{rowData.company_website} </span>;
			},
		},
		{
			title: "Venues",
			render: (rowData) => {
				return <span>{rowData.company_venue_number} Venue</span>;
			},
		},
		{
			title: "Verified",
			render: (rowData) => {
				return (
					<span>{rowData.company_verified ? "True" : "False"}</span>
				);
			},
		},
		{
			title: "Active",
			render: (rowData) => {
				return <span>{rowData.company_status ? "True" : "False"}</span>;
			},
		},
		{
			title: "Member Date",
			render: (rowData) => {
				return <span>{rowData.createdAt?.split("T")[0]}</span>;
			},
		},
		{
			title: "CaterMe",
			render: (rowData) => {
				return (
					<>
						{rowData.cater_me ? (
							<span>subscribed </span>
						) : (
							<div
								className="btn btn-success"
								onClick={() =>
									subscribeToNibjar(rowData, "CaterMe")
								}
							>
								subscribe
							</div>
						)}
					</>
				);
			},
		},
		{
			title: "Income/Expense",
			render: (rowData) => {
				return (
					<>
						{rowData.income_expense ? (
							<span>subscribed </span>
						) : (
							<div
								className="btn btn-success"
								onClick={() =>
									subscribeToNibjar(rowData, "Income/Expense")
								}
							>
								subscribe
							</div>
						)}
					</>
				);
			},
		},
		{
			title: "Billing",
			render: (rowData) => {
				return (
					<>
						{rowData.billing ? (
							<span>subscribed </span>
						) : (
							<div
								className="btn btn-success"
								onClick={() =>
									subscribeToNibjar(rowData, "Billing")
								}
							>
								subscribe
							</div>
						)}
					</>
				);
			},
		},
		{
			title: "Inventory",
			render: (rowData) => {
				return (
					<>
						{rowData.inventory ? (
							<span>subscribed </span>
						) : (
							<div
								className="btn btn-success"
								onClick={() =>
									subscribeToNibjar(rowData, "Inventory")
								}
							>
								subscribe
							</div>
						)}
					</>
				);
			},
		},
	];

	const renewed_date = moment().format("YYYY-MM-DD");

	const expiration_date = moment(renewed_date)
		.add(1, "months")
		.format("YYYY-MM-DD");

	const subscribeToNibjar = async (companyInfo, service) => {
		// switch case should be made to handle service with different API

		switch (service) {
			case "CaterMe":
				let memberInformation = {
					company_country: "nepal",
					company_city: companyInfo.company_city,
					company_address: companyInfo.company_address,
					company_facebook: companyInfo.company_facebook,
					company_website: companyInfo.company_website,
					company_phone: companyInfo.company_phone,
					company_mobile: companyInfo.company_mobile,
					company_email: companyInfo.company_email,
					company_venue_number: companyInfo.company_venue_number,
					company_name: companyInfo.company_name,
					company_terms_condition: "terms and condition for company",
					// user information
					first_name: companyInfo.first_name,
					last_name: companyInfo.last_name,
					user_gender: companyInfo.member_gender,
					user_dob: companyInfo.member_dob,
					user_email: companyInfo.member_email,
					user_mobile: companyInfo.primary_contact,
					user_phone: companyInfo.primary_contact,
					user_address: companyInfo.address,
					user_city: companyInfo.city,
					user_country: "nepal",
					password: "1234567890",
					// venue information
					venue_name: "Venue 1",
					venue_address: companyInfo.company_city,
					venue_city: companyInfo.company_address,
					venue_country: "nepal",
					venue_phone: companyInfo.company_phone,
					// subscription package
					subscription_type: "monthly",
					subscription_duration: 1,
					subscription_amount: 5500,
					subscription_status: "active",
					renewed_date,
					expiration_date,
				};

				dispatch(signUpCompany(memberInformation));
				break;
			case "Income/Expense":
				alert("Income/Expense Software work in progress . . .");
				break;
			case "Billing":
				alert("Billing Software work in progress . . .");
				break;
			case "Inventory":
				alert("Inventory Software work in progress . . .");
				break;
		}
	};

	return (
		<div className="main-content">
			<Table
				cols={tableConstants}
				data={evaNepalMembers}
				isDark
				bordered
				striped
				hoverable
			/>
		</div>
	);
}
