import {
	FETCH_USER_LOGOUT,
	FETCH_SUPPORT_REQUEST,
	FETCH_SUPPORT_SUCCESS,
	FETCH_SUPPORT_FAILURE,
	UPDATE_SUPPORT_REQUEST,
	UPDATE_SUPPORT_SUCCESS,
	UPDATE_SUPPORT_FAILURE,
} from "../action-types";

import axios from "axios";
import {
	GET_ISSUES_LIST,
	SEARCH_ISSUES_LIST,
	UPDATE_SUPPORT_ISSUE,
} from "../../constants/endpoints";

import setAuthToken from "../../helpers/setAuthToken";

import {
	isEmptyObject,
	sessionInfo,
	showToastMessage,
} from "../../helpers/functions";

async function _handleError(error, dispatch) {
	var status = 504;

	if (!isEmptyObject(error.response)) {
		status = error.response.status;
	}

	if (status === 403) {
		// history.push({ pathname: "/" });dispatch();
		await dispatch({
			type: FETCH_USER_LOGOUT,
			error: error.response.data.message,
		});
		showToastMessage(error.response.data.message, "E");
	} else if (status === 400) {
		showToastMessage(error.response.data.message, "E");
		await dispatch({
			type: FETCH_SUPPORT_FAILURE,
			error: error.response.data.message,
		});
	} else {
		showToastMessage(error.message, "E");
		await dispatch({
			type: FETCH_SUPPORT_FAILURE,
			error: error.message,
		});
	}
}

export const getSupportList = () => async (dispatch) => {
	const sessionData = await sessionInfo();

	if (localStorage.fooventLoggedIn) {
		setAuthToken(sessionData.token);
	}

	dispatch({
		type: FETCH_SUPPORT_REQUEST,
	});

	await axios
		.get(GET_ISSUES_LIST)
		.then((response) => {
			if (response.data.success) {
				dispatch({
					type: FETCH_SUPPORT_SUCCESS,
					payload: response.data.result,
				});
			} else {
				showToastMessage(response.data.message, "E");
				dispatch({
					type: FETCH_SUPPORT_FAILURE,
					error: response.data.message,
				});
			}
		})
		.catch((err) => {
			_handleError(err, dispatch);
		});
};

export const searchSupportList = (searchData) => async (dispatch) => {
	const sessionData = await sessionInfo();

	if (localStorage.fooventLoggedIn) {
		setAuthToken(sessionData.token);
	}

	dispatch({
		type: FETCH_SUPPORT_REQUEST,
	});

	await axios
		.post(SEARCH_ISSUES_LIST, searchData)
		.then((response) => {
			if (response.data.success) {
				dispatch({
					type: FETCH_SUPPORT_SUCCESS,
					payload: response.data.result,
				});
			} else {
				showToastMessage(response.data.message, "E");
				dispatch({
					type: FETCH_SUPPORT_FAILURE,
					error: response.data.message,
				});
			}
		})
		.catch((err) => {
			_handleError(err, dispatch);
		});
};

export const updateSupportIssue = (supportData) => async (dispatch) => {
	const sessionData = await sessionInfo();

	if (localStorage.fooventLoggedIn) {
		setAuthToken(sessionData.token);
	}

	dispatch({
		type: UPDATE_SUPPORT_REQUEST,
	});

	await axios
		.put(UPDATE_SUPPORT_ISSUE, supportData)
		.then((response) => {
			if (response.data.success) {
				dispatch({
					type: UPDATE_SUPPORT_SUCCESS,
					payload: response.data.result,
				});
			} else {
				showToastMessage(response.data.message, "E");
				dispatch({
					type: UPDATE_SUPPORT_FAILURE,
					error: response.data.message,
				});
			}
		})
		.catch((err) => {
			_handleError(err, dispatch);
		});
};
