import {
	// FETCH
	FETCH_CATER_SUBSCRIPTION_REQUEST,
	FETCH_CATER_SUBSCRIPTION_SUCCESS,
	FETCH_CATER_SUBSCRIPTION_FAILURE,
	// FETCH INDIVIDUAL
	FETCH_COMPANY_SUBSCRIPTION_REQUEST,
	FETCH_COMPANY_SUBSCRIPTION_SUCCESS,
	FETCH_COMPANY_SUBSCRIPTION_FAILURE,
	// ADD
	ADD_CATER_SUBSCRIPTION_REQUEST,
	ADD_CATER_SUBSCRIPTION_SUCCESS,
	ADD_CATER_SUBSCRIPTION_FAILURE,
	// UPDATE
	UPDATE_CATER_SUBSCRIPTION_REQUEST,
	UPDATE_CATER_SUBSCRIPTION_SUCCESS,
	UPDATE_CATER_SUBSCRIPTION_FAILURE,
	// DELETE
	DELETE_CATER_SUBSCRIPTION_REQUEST,
	DELETE_CATER_SUBSCRIPTION_SUCCESS,
	DELETE_CATER_SUBSCRIPTION_FAILURE,
} from "../action-types";

const initialState = {
	caterSubscriptionList: [],
	caterCompanySubscription: [],
	isLoading: false,
	error: "",
};

const caterPackageReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case FETCH_CATER_SUBSCRIPTION_REQUEST:
		case ADD_CATER_SUBSCRIPTION_REQUEST:
		case UPDATE_CATER_SUBSCRIPTION_REQUEST:
		case DELETE_CATER_SUBSCRIPTION_REQUEST:
		case FETCH_COMPANY_SUBSCRIPTION_REQUEST:
			return {
				...state,
				isLoading: true,
			};

		case FETCH_COMPANY_SUBSCRIPTION_SUCCESS:
			return {
				...state,
				isLoading: false,
				caterCompanySubscription: payload,
			};

		case FETCH_CATER_SUBSCRIPTION_SUCCESS:
		case ADD_CATER_SUBSCRIPTION_SUCCESS:
		case UPDATE_CATER_SUBSCRIPTION_SUCCESS:
		case DELETE_CATER_SUBSCRIPTION_SUCCESS:
			return {
				...state,
				caterSubscriptionList: payload,
				isLoading: false,
			};

		case FETCH_CATER_SUBSCRIPTION_FAILURE:
		case ADD_CATER_SUBSCRIPTION_FAILURE:
		case UPDATE_CATER_SUBSCRIPTION_FAILURE:
		case DELETE_CATER_SUBSCRIPTION_FAILURE:
		case FETCH_COMPANY_SUBSCRIPTION_FAILURE:
			return {
				...state,
				error: payload,
				isLoading: false,
			};
		default:
			return state;
	}
};

export default caterPackageReducer;
