import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Table from "../../components/table/Table";
import { getAllCompanies } from "../../redux/actions/company-action";

// import { getAllCompanies } from "../../redux/index";

export default function Company() {
	const dispatch = useDispatch();
	const companiesData = useSelector((state) => state.company.companies);

	useEffect(() => {
		dispatch(getAllCompanies());
	}, [dispatch]);

	const tableConstants = [
		{
			title: "Company Name",
			render: (rowData) => {
				return (
					<span>
						{rowData.company_name} - {rowData.company_address},{" "}
						{rowData.company_city} - [{rowData.company_phone} ,
						{rowData.company_mobile}]
					</span>
				);
			},
		},
		{
			title: "Active",
			render: (rowData) => {
				return <span>{rowData.active_users || 0} Users</span>;
			},
		},
		{
			title: "Online",
			render: (rowData) => {
				return <span>{rowData.online_users || 0} Users</span>;
			},
		},
		{
			title: "Last Active",
			render: (rowData) => {
				return (
					<span>
						{rowData.last_active !== null
							? moment(rowData?.last_active).format()
							: rowData.last_active}
					</span>
				);
			},
		},
		{
			title: "Venues",
			render: (rowData) => {
				return <span>{rowData.company_venue_number} Venue</span>;
			},
		},
		{
			title: "Verified",
			render: (rowData) => {
				return (
					<span>{rowData.company_verified ? "True" : "False"}</span>
				);
			},
		},
		{
			title: "Created | Updated",
			render: (rowData) => {
				return (
					<span>
						{rowData.createdAt?.split("T")[0]} |{" "}
						{rowData.updatedAt?.split("T")[0] || "-"}
					</span>
				);
			},
		},
		{
			title: "Deactivated",
			render: (rowData) => {
				return (
					<span>
						{rowData.company_deactivated ? "True" : "False"}
					</span>
				);
			},
		},
		{
			title: "Action",
			render: (rowData) => {
				return <button className="btn btn-success">Activate</button>;
			},
		},
	];

	return (
		<div className="main-content">
			<Table
				cols={tableConstants}
				data={companiesData}
				isDark
				bordered
				striped
				hoverable
			/>
		</div>
	);
}
