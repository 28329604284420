import axios from "axios";

import { GET_ALL_WIN_LOSS } from "../../constants/endpoints";

import {
	// user actions
	FETCH_USER_LOGOUT,
	FETCH_USER_FAILURE,
	// win loss actions
	FETCH_WINLOSS_REQUEST,
	FETCH_WINLOSS_SUCCESS,
	FETCH_WINLOSS_FAILURE,
	// UPDATE_WIN_LOSS,
} from "../action-types";

import {
	sessionInfo,
	showToastMessage,
	isEmptyObject,
} from "../../helpers/functions";
import setAuthToken from "../../helpers/setAuthToken";

async function _handleError(error, dispatch) {
	var status = 504;

	if (!isEmptyObject(error.response)) {
		status = error.response.status;
	}

	if (status === 403) {
		await dispatch({
			type: FETCH_USER_LOGOUT,
			error: error.response.data.message,
		});
		showToastMessage(error.response.data.message, "E");
	} else if (status === 400) {
		showToastMessage(error.response.data.message, "E");
		await dispatch({
			type: FETCH_USER_FAILURE,
			error: error.response.data.message,
		});
	} else {
		showToastMessage(error.message, "E");
		await dispatch({
			type: FETCH_USER_FAILURE,
			error: error.message,
		});
	}
}

export const getAllWinloss = () => async (dispatch) => {
	const sessionData = await sessionInfo();

	if (localStorage.fooventLoggedIn) {
		setAuthToken(sessionData.token);
	}

	dispatch({
		type: FETCH_WINLOSS_REQUEST,
	});

	try {
		const response = await axios.get(GET_ALL_WIN_LOSS);

		dispatch({
			type: FETCH_WINLOSS_SUCCESS,
			payload: response.data.result,
		});
	} catch (err) {
		dispatch({
			type: FETCH_WINLOSS_FAILURE,
			error: err.message,
		});

		_handleError(err, dispatch);
	}
};
